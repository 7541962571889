/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import UploadFile from '../../components/UploadFiles'
import { SelectPicker, Table } from 'rsuite'
import { useDispatch, useSelector } from 'react-redux'
import InputText from '../../components/InputText'
import RoutineService from '../../services/routine.service'
import { _pushToastMessage } from '../../helpers/messages'
import { setLoading } from '../../redux/slicers/loader'
import WhisperComponent from '../../components/WhisperComponent'
import { validateYupSchema } from '../../helpers/utils'
import { RoutineMediaSchema } from '../../validations/routine_media.validations'
import ErrorValidation from '../../components/ErrorValidation'

const MediaRoutine = ({
    handleClose,
    data,
    open,
    updateMediatableData,
}: {
    handleClose: () => void
    updateMediatableData: ({ medias, routine_id }: { medias: any[]; routine_id: string }) => void
    data: { medias: []; id: string }
    open: boolean
}) => {
    const [state, setState] = useState<any>({
        routine_id: '',
        image: '',
        type: '',
        url: '',
        validations: {},
        medias: [],
    })

    const { loader } = useSelector((state: any) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        if (open) {
            setState({
                routine_id: data.id,
                image: '',
                type: '',
                url: '',
                validations: {},
                medias: data.medias || [],
            })
        }
    }, [open])

    const handleOnChange = (data: { name: string; value: string }) => {
        if (data.name === 'type') {
            state.url = ''
            state.image = ''
        }

        if (state.validations[data.name]) {
            delete state.validations[data.name]
        }

        setState({ ...state, [data.name]: data.value })
    }

    const _handleSubmit = async () => {
        if (state.medias?.length > 0) {
            _pushToastMessage({
                header: 'Error',
                type: 'error',
                text: 'Ya se ha agregado una imagen o video a la rutina',
            })
            return
        }

        const validations = await validateYupSchema(RoutineMediaSchema, state)
        if (validations) {
            setState({ ...state, validations })
            return
        }

        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new RoutineService().addMedia(state)
            const { routine } = response.data
            setState({ ...state, type: '', url: '', image: '', medias: routine.medias })
            updateMediatableData({ medias: routine.medias, routine_id: routine.id })
            _pushToastMessage({
                header: state.type === '1' ? 'Imagen' : 'Video',
                type: 'success',
                text: `Se ha agregado la ${state.type === '1' ? 'imagen' : 'url'} correctamente`,
            })
            dispatch(setLoading({ isLoading: false }))
        } catch (e: any) {
            _pushToastMessage({
                header: 'Error',
                type: 'error',
                text: 'No se pudo agregar la data, intenta de nuevo o contacta al administrador',
            })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleDeleteMedia = async (media: { pivot: { routine_id: string; media_id: string } }) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new RoutineService().deleteMedia(media.pivot)
            const { routine } = response.data
            setState({ ...state, medias: routine.medias })
            updateMediatableData({ medias: routine.medias, routine_id: routine.id })
            _pushToastMessage({
                header: 'Imagen',
                type: 'success',
                text: `Se ha eliminado la ${state.type === '1' ? 'imagen' : 'url'} correctamente`,
            })
            dispatch(setLoading({ isLoading: false }))
        } catch (e: any) {
            _pushToastMessage({
                header: 'Error',
                type: 'error',
                text: 'No se pudo eliminar la data, intenta de nuevo o contacta al administrador',
            })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    return (
        <div className="w-full flex flex-wrap">
            <div className="mb-2 w-full">
                <label className="text-[12px]">Informa el tipo de media</label>
                <SelectPicker
                    data={[
                        { id: '1', name: 'Imagen' },
                        { id: '2', name: 'Video' },
                    ]}
                    labelKey="name"
                    valueKey="id"
                    placeholder="Selecciona un tipo de media"
                    style={{ width: '100%' }}
                    value={state.type}
                    onChange={(value: any) => handleOnChange({ name: 'type', value })}
                />
            </div>
            {state.type === '2' && (
                <div className="mb-2 w-full">
                    <InputText
                        name="url"
                        type="text"
                        label="Url video"
                        placeholder="Ingresa la url del video"
                        validations={state.validations}
                        onChange={({ target }) => handleOnChange({ name: target.name, value: target.value })}
                        value={state.url}
                    />
                </div>
            )}
            {state.type === '1' && (
                <div className="mb-2 w-full">
                    <label className="text-[12px]">Ingresa una imagen</label>
                    <UploadFile
                        text="Agregar imagen al codigo QR"
                        onChange={(data: any) => handleOnChange({ name: 'image', value: data.base64 })}
                        accept=".png"
                    />
                    <ErrorValidation
                        validations={state.validations}
                        name="image"
                        className="text-red-400 text-[11px] text-start"
                    />
                </div>
            )}
            <div className="flex justify-end w-full mt-5">
                <button className="button me-3 border-red-400" onClick={handleClose} disabled={loader.isLoading}>
                    Cerrar
                </button>
                <button className="button" onClick={_handleSubmit} disabled={loader.isLoading || !state.type}>
                    Confirmar
                </button>
            </div>
            {state.medias.length > 0 && (
                <>
                    <hr className="w-full h-1" />

                    <div className="w-full max-h-[300px] overflow-y-auto mt-5">
                        <Table
                            autoHeight
                            rowHeight={110}
                            className="w-[100%] "
                            data={state.medias}
                            locale={{ emptyMessage: 'No se encontraron usuarios con los datos informados' }}
                            loading={loader.isLoading}
                        >
                            <Table.Column flexGrow={1}>
                                <Table.HeaderCell align="center">Url</Table.HeaderCell>
                                <Table.Cell>
                                    {(media) => {
                                        return (
                                            <div className="flex h-full items-center justify-start">
                                                <a href={media.path} target="_blank" rel="noreferrer">
                                                    <span className="size-08">{`${media.path}`}</span>
                                                </a>
                                            </div>
                                        )
                                    }}
                                </Table.Cell>
                            </Table.Column>

                            <Table.Column flexGrow={1}>
                                <Table.HeaderCell align="center">Image</Table.HeaderCell>
                                <Table.Cell align="center">
                                    {(media) => {
                                        if (media.type === '1') {
                                            return (
                                                <div className="flex h-full items-center justify-start">
                                                    <img src={media.path} alt={media.name} className="max-w-[70px]" />
                                                </div>
                                            )
                                        }

                                        if (media.type === '2') {
                                            return (
                                                <div className="flex h-full items-center justify-start">
                                                    <i className="zmdi zmdi-youtube text-[60px]"></i>
                                                </div>
                                            )
                                        }
                                    }}
                                </Table.Cell>
                            </Table.Column>

                            <Table.Column flexGrow={1}>
                                <Table.HeaderCell align="center">{''}</Table.HeaderCell>
                                <Table.Cell align="center">
                                    {(media: any) => {
                                        return (
                                            <button onClick={() => _handleDeleteMedia(media)}>
                                                <WhisperComponent text={`Eliminar`} capitalizeOff>
                                                    <i className="zmdi zmdi-delete text-[20px]"></i>
                                                </WhisperComponent>
                                            </button>
                                        )
                                    }}
                                </Table.Cell>
                            </Table.Column>
                        </Table>
                    </div>
                </>
            )}
        </div>
    )
}

export default MediaRoutine
