/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import InputText from "../../components/InputText";
import ModalComponent from "../../components/ModalComponent";
import { SelectPicker } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";
import { _pushToastMessage } from "../../helpers/messages";
import UserService from "../../services/users.service";
import IRole from "../../interfaces/IRole";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/slicers/loader";
import RolesService from "../../services/roles.service";

interface IFilterUserForm {
    open: boolean;
    handleClose: () => void;
    handleFilter: (data: any) => void;
    handleSaveFilter: (data: any) => void;
}

const FilterUserForm = ({ open, handleClose, handleFilter, handleSaveFilter }: IFilterUserForm) => {

    const [state, setState] = useState({
        data: {
            name: "",
            relevant_info: "",
            created_at: "",
            status: "",
            role: ""
        },
        validations: {}
    })

    const [roles, setRoles] = useState<IRole[]>([])

    const { loader } = useSelector((state: any) => state);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!open) return;

        if (roles.length === 0) _getRoles();
    }, [open])


    const _handleOnChange = (e: any) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value
            }
        })
    }

    const _handleOnChangeRsuite = ({ name, value }: { name: string, value: string }) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value
            }
        })
    }

    const _handleSubmit = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const params = new URLSearchParams(state.data);
            const response = await new UserService().filterUsers(params.toString());
            _pushToastMessage({ type: "success", header: "Éxito", text: "Filtro aplicado correctamente" });
            handleFilter(response.data);
            handleSaveFilter(state.data);
            handleClose();
            dispatch(setLoading({ isLoading: false }))
        } catch (e: any) {
            _pushToastMessage({ type: "error", header: "Error", text: "Ocurrió un error al filtrar los usuarios" });
            dispatch(setLoading({ isLoading: false }))
        }
    }



    const _getRoles = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const roles = await new RolesService().getRoles();
            setRoles(roles.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (err: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: "error", header: "Error", text: "No fue posible cargar las roles" })
        }
    }


    return (
        <ModalComponent open={open} handleClose={handleClose} title="Filtrar usuarios">
            <div className="w-full flex justify-between flex-wrap">
                <div className="w-full">
                    <InputText label="Nombre" name="name" validations={state.validations} onChange={_handleOnChange} value={state.data.name} />
                </div>
                
                <div className="w-full sm:w-[49%]">
                    <InputText label="Fecha registro" name="created_at" type="date" validations={state.validations} onChange={_handleOnChange} value={state.data.created_at} />
                </div>
                <div className="mb-2 w-full sm:w-[49%]">
                    <span className={`text-[12px] `}>Status</span>
                    <SelectPicker
                        className="w-full "
                        value={state.data?.status}
                        data={[
                            { label: "Activo", value: "active" },
                            { label: "Inactivo", value: "inactive" }
                        ]}
                        onChange={(value: any) => _handleOnChangeRsuite({
                            value, name: "status"
                        })}
                        placeholder="Seleccione un status"
                        locale={
                            {
                                emptyMessage: "No hay resultados",
                                searchPlaceholder: "Buscar",
                                noResultsText: "No hay resultados"
                            }
                        }
                    />
                    <ErrorValidation validations={state.validations
                    } name="status" className="text-red-400 text-[11px] text-end" />
                </div>

                <div className="mb-2 w-full">
                    <span className={`text-[12px] `}>Role</span>
                    <SelectPicker
                        className="w-full "
                        value={state.data?.role}
                        data={roles} onChange={(value: any) => _handleOnChangeRsuite({
                            value, name: "role"
                        })}
                        placeholder="Seleccione un rol"
                        loading={loader.isLoading}
                        locale={
                            {
                                emptyMessage: "No hay resultados",
                                searchPlaceholder: "Buscar",
                                noResultsText: "No hay resultados"
                            }
                        }
                    />
                    <ErrorValidation validations={state.validations
                    } name="role" className="text-red-400 text-[11px] text-end" />
                </div>

                <div className="w-full">
                    <InputText label="Información relevante" name="relevant_info" validations={state.validations} onChange={_handleOnChange} value={state.data.relevant_info} />
                </div>

                <div className="flex justify-end w-full mt-10">
                    <button className=" button me-3 border-red-400" onClick={handleClose}>Cerrar</button>
                    <button className="button" onClick={_handleSubmit} disabled={loader.isLoading}>Confirmar</button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default FilterUserForm;