/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import routes, { AuxiliarRoutes } from './routes'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { IRoutes } from '../interfaces/IRoutes'
import { useSelector } from 'react-redux'
import InfoModal from '../modals/InfoModal/InfoModal'

const AppRouter = () => {
    const [routerData, setRouterData] = useState<IRoutes[]>([])
    const { session } = useSelector((state: any) => state)

    useEffect(() => {
        let validRoutes: IRoutes[] = []

        if (session.isLogged) {
            const mainRole: string = session?.profile?.roles[0]?.value

            validRoutes = routes?.filter(
                (route: any) => route.permissions.includes(mainRole) || route.permissions.includes('all')
            )

            setRouterData([...validRoutes, ...AuxiliarRoutes])
            return
        }

        setRouterData([...routes, ...AuxiliarRoutes])
    }, [session])

    return (
        <BrowserRouter>
            <InfoModal />
            <Routes>
                {routerData.map((route: IRoutes) => (
                    <Route path={route.path} element={<route.component />} key={route.path} />
                ))}
                <Route path="*" element={<>Nothing Here</>} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter
