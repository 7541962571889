/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { useEffect, useState } from 'react'
import PlaceholderParagraph from 'rsuite/esm/Placeholder/PlaceholderParagraph'
import DashboardService from '../../services/dashboard.service'
import { _pushToastMessage } from '../../helpers/messages'
import WhisperComponent from '../WhisperComponent'
import { Tooltip, Whisper } from 'rsuite'

interface ICardUserGrowth {
    className?: string
    period?: any
}

const CardUserGrowth = ({ className = 'w-full', period }: ICardUserGrowth) => {
    const [userGrowthData, setUserGrowthData] = useState({
        current: {
            users: 0,
            date: moment().format('YYYY-MM-DD'),
        },
        previous: {
            users: 0,
            date: moment().format('YYYY-MM-DD'),
        },
        chart: {
            growth: 0,
            current: 0,
            previous: 0,
            bars: [],
        },
        is_loading: true,
    })

    useEffect(() => {
        getUsersGrowth()
    }, [period])

    const getUsersGrowth = async () => {
        try {
            setUserGrowthData({ ...userGrowthData, is_loading: true })
            const response = await new DashboardService().getUsersGrowth({ interval: period.selected })
            response.data.chart.bars = response.data.chart.bars.sort((a: any, b: any) => {
                if (a.percentage < b.percentage) return 1
                if (a.percentage > b.percentage) return -1
                return 0
            })
            setUserGrowthData({
                ...userGrowthData,
                ...response.data,
                is_loading: false,
            })
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Error al obtener las actividades reciente' })
        }
    }

    const Circle = ({ color, width = 'w-5', height = 'h-5' }: { color: string; width?: string; height?: string }) => {
        return <div className={`rounded-full ${width} ${height} ${color}`}></div>
    }
    if (userGrowthData.is_loading) {
        return (
            <section className={`bg-white rounded-lg shadow-md border text-slate-400 text-sm  ${className}`}>
                <PlaceholderParagraph rows={4} active />
            </section>
        )
    }

    return (
        <section className={`bg-white rounded-lg shadow-md border text-slate-400 text-sm flex flex-col justify-between ${className}`}>
            <div className="w-full font-semibold mb-5 flex justify-between items-center">
                <span className='flex items-center'>
                    <i className={`zmdi zmdi-account text-gray-400 text-[18px] me-3`} />
                    Usuarios
                </span>
            </div>
            <div className="w-full flex">
                <div className="w-1/2 flex text-[11px] sm:text-[13px]">
                    <div className="w-1/3">
                        <div className="text-black">Nuevos</div>
                        <div className="flex items-center">
                            <Circle color="bg-slate-600" width="w-4" height="h-4" />
                            <span className="ms-2">{userGrowthData.chart?.growth}</span>
                        </div>
                    </div>
                    <div className="w-1/3">
                        <div className="text-black">Previos</div>
                        <div className="flex items-center">
                            <Circle color="bg-slate-400" width="w-4" height="h-4" />
                            <span className="ms-2">{userGrowthData.chart?.previous}</span>
                        </div>
                    </div>
                    <div className="w-1/3">
                        <div className="text-black">Total</div>
                        <div className="flex items-center">
                            <Circle color="bg-slate-200" width="w-4" height="h-4" />
                            <span className="ms-2">{userGrowthData.chart?.current}</span>
                        </div>
                    </div>
                </div>
                <div className="w-1/2">
                    <div className="relative w-full h-full flex items-center overflow-hidden rounded">
                        <WhisperComponent className="absolute h-10" text={`Total de usuarios: 100%`} capitalizeOff>
                            <div className={` w-[100%] h-10 bg-slate-200 rounded`} />
                        </WhisperComponent>
                        {userGrowthData.chart?.bars?.map((bar: any, index: number) => (
                            <Whisper
                                key={index}
                                trigger="hover"
                                placement={'autoVerticalEnd'}
                                controlId={`control-id-auto`}
                                speaker={<Tooltip>{`${bar.label} - ${parseInt(bar.percentage)}%`}</Tooltip>}
                            >
                                <div
                                    className={`absolute h-10 ${bar.color}  rounded z-10`}
                                    style={{ width: `${bar.percentage > 100 ? 100 : bar.percentage}%` }}
                                />
                            </Whisper>
                        ))}
                    </div>
                </div>
            </div>
            <div className="w-full flex text-[11px] justify-between mt-5">
                <div className='sm:w-1/2'>
                    <span className="font-semibold me-2 ">Fecha inicio consulta:</span>{' '}
                    {moment(userGrowthData.previous.date).format('DD/MM/yyyy')}
                </div>
                <div className='border-l-2 ps-3 sm:w-1/2 sm:text-end'>
                    <span className="font-semibold me-2">Fecha termino consulta:</span>{' '}
                    {moment(userGrowthData.current.date).format('DD/MM/yyyy')}
                </div>
            </div>
        </section>
    )
}

export default CardUserGrowth
