/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import MedicalHistoryService from '../../services/medical_histories.service'
import { useParams } from 'react-router-dom'
import InputText from '../../components/InputText'
import ErrorValidation from '../../components/ErrorValidation'
import { _pushToastMessage } from '../../helpers/messages'
import { validateYupSchema } from '../../helpers/utils'
import { MedicalHistorySchema } from '../../validations/medical_history.validations'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
const MedicalHistory = () => {
    const [state, setState] = useState({
        id: '',
        title: '',
        description: '',
        url_file: '',
        user: {
            name: '',
            phone_number: '',
            email: '',
        },
        validations: {},
    }) as any

    const [isEditing, setIsEditing] = useState(false)

    const params = useParams() as { id: string }
    const urlParams = new URLSearchParams(window.location.search)
    const dispatch = useDispatch()
    const { isLoading } = useSelector((state: any) => state.loader)

    useEffect(() => {
        _getHistory()
        if (urlParams.has('isediting') && urlParams.get('isediting') === '1') {
            setIsEditing(true)
        }
    }, [])

    const _getHistory = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new MedicalHistoryService().getMedicalHistory({ id: params.id })
            setState({ ...state, ...response.data })
            dispatch(setLoading({ isLoading: false }))
        } catch (e) {
            _pushToastMessage({ header: 'Error', text: 'No se pudo obtener esta información', type: 'error' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleSubmit = async () => {
        const validations = await validateYupSchema(MedicalHistorySchema, state)

        if (validations) {
            setState({ ...state, validations })
            return
        }

        try {
            dispatch(setLoading({ isLoading: true }))
            await new MedicalHistoryService().updateHistory({ ...state })
            setIsEditing(false)
            _pushToastMessage({ header: 'Éxito', text: 'Información relevante actualizada', type: 'success' })
            dispatch(setLoading({ isLoading: false }))
        } catch (e) {
            _pushToastMessage({ header: 'Error', text: 'No se pudo actualizar la información relevante', type: 'error' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _downloadFile = () => {
        window.open(state.url_file, '_blank')
    }

    const _handleOnChange = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    if (isEditing) {
        return (
            <div className="w-full flex justify-center">
                <div className="p-5 rounded w-5/6 border shadow text-[14px]">
                    <section className="mb-4 flex flex-col">
                        <div className="me-3">Usuario</div>
                        <div className="ms-2">{state.user?.name}</div>
                    </section>
                    <section className="mb-4 flex flex-col ">
                        <div className="me-3 ">Correo electrónico</div>
                        <div className="ms-2">{state.user?.email}</div>
                    </section>
                    <section className="mb-4 flex flex-col ">
                        <div className="me-3 ">Teléfono</div>
                        <div className="ms-2">{state.user?.phone_number}</div>
                    </section>
                    <section className="mb-4 flex flex-col">
                        <InputText
                            name="title"
                            value={state.title}
                            onChange={(e) => setState({ ...state, title: e.target.value })}
                            validations={state.validations}
                            label="Titulo"
                        />
                    </section>
                    <section className="mb-6 flex flex-col">
                        <label className={`${state.validations?.description ? 'text-red-400' : ''} text-[12px]`}>
                            Descripcion
                        </label>
                        <textarea
                            className={`w-full outline-none ${
                                state.validations?.description ? 'input-text-red' : 'input-text'
                            }`}
                            rows={5}
                            name="description"
                            onChange={_handleOnChange}
                            value={state.description}
                        ></textarea>
                        <ErrorValidation
                            validations={state.validations}
                            name="description"
                            className="text-red-400 text-[11px] text-end"
                        />
                    </section>
                    {state.url_file && (
                        <section className="flex justify-end my-3">
                            <button
                                className="btn border px-3 py-1 rounded shadow"
                                onClick={_handleSubmit}
                                disabled={isLoading}
                            >
                                Guardar
                            </button>
                        </section>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="w-full flex justify-center">
            <div className="p-5 rounded w-5/6 border shadow text-[14px]">
                <section className="mb-4 flex flex-col">
                    <div className="me-3 font-semibold">Usuario</div>
                    <div className="ms-2">{state.user?.name}</div>
                </section>
                <section className="mb-4 flex flex-col">
                    <div className="me-3 font-semibold">Correo electrónico</div>
                    <div className="ms-2">{state.user?.email}</div>
                </section>
                <section className="mb-4 flex flex-col">
                    <div className="me-3 font-semibold">Teléfono</div>
                    <div className="ms-2">{state.user?.phone_number}</div>
                </section>
                <section className="mb-4 flex flex-col">
                    <div className="me-3 font-semibold">Titulo</div>
                    <div className="ms-2">{state.title}</div>
                </section>
                <section className="mb-6 flex flex-col">
                    <div className="me-3 font-semibold">Descripción</div>
                    <div className="ms-2">{state.description}</div>
                </section>
                {state.url_file && (
                    <section className="flex justify-end my-3">
                        <button
                            className="btn border px-3 py-1 rounded shadow me-3"
                            onClick={() => setIsEditing(true)}
                            disabled={isLoading}
                        >
                            Editar
                        </button>
                        <button className="btn border px-3 py-1 rounded shadow" onClick={_downloadFile}>
                            Descargar archivo
                        </button>
                    </section>
                )}
            </div>
        </div>
    )
}

export default MedicalHistory
