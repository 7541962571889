/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import NotificationCard from './NotificationCard'
import NotificationsService from '../../services/notifications.service'
import { _pushToastMessage } from '../../helpers/messages'
import OutsideAlerter from '../../hooks/useOutsideEvent'
import { Loader } from 'rsuite'
import NotificationModal from './NotificationModal'

const Notifications = ({ className }: { className?: string }) => {
    const { session } = useSelector((state: any) => state)
    const [showMenu, setShowMenu] = useState(false)
    const [notifications, setNotifications] = useState<any[]>([])
    const [eventNotifications, setEventNotifications] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [notificationModal, setNotificationModal] = useState<any>({
        open: false,
        notification: {},
    })
    useEffect(() => {
        getNotifications()
    }, [])

    useEffect(() => {
        const token = session?.tokens?.accessToken
        const eSource = new EventSource(
            `${process.env.REACT_APP_API_NOTIFICATIONS}/notifications/routines?auth=${token}`,
            {
                withCredentials: true,
            }
        )
        eSource.onmessage = (event) => {
            const data = JSON.parse(event.data)
            setEventNotifications(data.notifications)
        }

        eSource.onerror = (error) => {
            console.error('EventSource failed:', error)
        }

        return () => {
            eSource.close()
        }
    }, [])

    useEffect(() => {
        _handleNewNotifications(eventNotifications)
    }, [eventNotifications])

    const getNotifications = async () => {
        try {
            const notifications = await new NotificationsService().getNotifications()
            setNotifications(notifications?.data || [])
        } catch (error) {
            _pushToastMessage({ header: 'Error', text: 'No se pudo obtener las notificaciones', type: 'error' })
        }
    }

    const _handleNewNotifications = (newNotificationsList: any) => {
        const newNotifications = newNotificationsList.filter((newNotification: any) => {
            return !notifications.some((notification: any) => notification.id === newNotification.id)
        })

        if (newNotifications.length > 0) {
            const currentNotifications = [...newNotifications, ...notifications]
            const orderedCurrentNotifications = currentNotifications.sort((a, b) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            })
            setNotifications(orderedCurrentNotifications)
            console.log('orderedCurrentNotifications', orderedCurrentNotifications)
        }
    }

    const _handleMarkAsRead = async (notification: any) => {
        if (notification.is_read) return
        try {
            setLoading(true)
            await new NotificationsService().markAsRead(notification.id)
            await getNotifications()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const _handleMarkAllAsRead = async () => {
        try {
            setLoading(true)
            setShowMenu(false)
            await new NotificationsService().markAllAsRead()
            await getNotifications()
            setLoading(false)
        } catch (error) {
            setLoading(false)
            _pushToastMessage({
                header: 'Error',
                text: 'No se pudo marcar todas las notificaciones como leídas',
                type: 'error',
            })
        }
    }

    return (
        <div className={`${className} relative`}>
            <NotificationModal {...notificationModal} onClose={() => setNotificationModal({ open: false })} />
            <OutsideAlerter onClickOutside={() => setShowMenu(false)}>
                <button
                    className="button !border-none !text-gray-500 !text-[24px] !relative"
                    onClick={() => setShowMenu(!showMenu)}
                >
                    {loading && <Loader className="absolute" backdrop color="#F00" />}
                    <i className="zmdi zmdi-notifications-active"></i>
                    {notifications?.filter((notification) => notification.is_read === false)?.length > 0 &&
                        notifications?.filter((notification: any) => notification.is_read === false) && (
                            <i className="zmdi zmdi-alert-polygon absolute !text-[16px] !text-red-500 right-1"></i>
                        )}
                </button>
                {showMenu && (
                    <div className="absolute z-50 top-[6hv] -right-10 sm:right-0 bg-white shadow-lg w-[300px] max-w-[80vw] border border-gray-200 rounded overflow-hidden">
                        {notifications.length > 0 && (
                            <>
                                <div className="w-full max-h-[400px] overflow-y-auto lectogram-scrollbar">
                                    {notifications?.map((notification: any, index: number) => (
                                        <NotificationCard
                                            key={index}
                                            notification={notification}
                                            onClick={() => {
                                                setNotificationModal({ open: true, notification })
                                                setShowMenu(false)
                                                _handleMarkAsRead(notification)
                                            }}
                                        />
                                    ))}
                                </div>
                                {notifications?.filter((notification) => notification.is_read === false)?.length >
                                    0 && (
                                    <div className="py-2 px-4 text-end text-gray-500 text-[12px]">
                                        <button
                                            className="button !border-none !text-gray-500 !text-[12px] hover:!text-blue-500"
                                            onClick={_handleMarkAllAsRead}
                                        >
                                            <i className="zmdi zmdi-check-all me-1"></i>
                                            Marcar todas como leído
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                        {notifications.length === 0 && (
                            <div className="p-4 text-center text-gray-500">No hay notifications</div>
                        )}
                    </div>
                )}
            </OutsideAlerter>
        </div>
    )
}

export default Notifications
