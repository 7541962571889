const CardManualAlexa = ({
    description,
    commands,
    example,
    title,
    search,
}: {
    description: string
    commands: string[]
    example: string
    title: string
    search: string
}) => {
    const highlightText = (text: string, search: string) => {
        if (search === '') return text
        const regex = new RegExp(search, 'gi')
        return text.replace(regex, `<span class="font-semibold text-blue-500">${search}</span>`)
    }

    return (
        <section className="w-full sm:w-[49%] bg-gray-100 p-4 rounded shadow mb-5 flex flex-col justify-between">
            <div className="flex w-full mb-5">
                <div className="w-full font-semibold" dangerouslySetInnerHTML={{ __html: highlightText(title, search) }}/>
            </div>

            <div className="flex w-full mb-3 text-[14px]">
                <div className="w-1/3">Descripción:</div>
                <div className="w-2/3" dangerouslySetInnerHTML={{ __html: highlightText(description, search) }} />
            </div>

            <hr className="w-full border-gray-200 my-3" />

            <div className="flex w-full mb-3 text-[14px]">
                <div className="w-1/3">Comando:</div>
                <div className="w-2/3">
                    <ul>
                        {commands.map((command, index) => (
                            <li key={index}>{command}</li>
                        ))}
                    </ul>
                </div>
            </div>

            <hr className="w-full border-gray-200 my-3" />

            <div className="flex w-full text-[14px]">
                <div className="w-1/3">Ejemplo:</div>
                <div className="w-2/3 italic font-semibold">{example}</div>
            </div>
        </section>
    )
}

export default CardManualAlexa
