/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

const NotificationCard = ({ notification, onClick }: { notification: any; onClick: (id: number) => void }) => {
    const [state, setState] = useState<any>({
        title: '',
        description: '',
        user: {},
        is_read: true,
        icon: 'zmdi-notifications-active',
    })

    useEffect(() => {
        _handleNotification()
    }, [notification])

    const _handleNotification = () => {
        const [hour, minute] = notification?.notifiable?.start_time.split(':')
        const notificationDay = new Date(notification?.created_at).toLocaleDateString()

        switch (notification?.type) {
            case 'routine':
            case 'routine:start':
                setState({
                    title: notification?.notifiable?.name,
                    description: `¡Ya casi es la hora! 😁 <br/> Esta rutina debe empezar a las ${hour}:${minute}`,
                    user: notification?.user,
                    is_read: notification?.is_read,
                    day: notificationDay,
                    icon: 'zmdi-notifications-active',
                })
                break
            case 'routine:create':
            case 'routine:update':
            case 'task:create':
            case 'task:update':
                setState({
                    title: notification?.title,
                    description: notification.description,
                    user: notification?.user,
                    is_read: notification?.is_read,
                    day: notificationDay,
                    icon: 'zmdi-check-all',
                })
                break
            default:
                break
        }
    }
    return (
        <>
            <div
                className="w-full cursor-pointer p-3 hover:bg-gray-50 hover:shadow relative"
                onClick={() => onClick(notification.id)}
            >
                {state.is_read === false && (
                    <i className="zmdi zmdi-alert-polygon absolute !text-[16px] !text-red-400 right-2 top-2"></i>
                )}
                <div className="flex items-center">
                    <i className={`zmdi ${state.icon} me-4 !text-[24px] text-blue-600`}></i>
                    <div className="relative">
                        <div className="text-[14px] text-gray-500 w-[225px] truncate mb-2">{state.title}</div>
                        <div
                            className="text-[12px] text-gray-500"
                            dangerouslySetInnerHTML={{ __html: state.description }}
                        />
                        <div className="text-[11px] text-gray-500 flex justify-between mt-2">
                            <span className="me-2">{state.user?.name}</span>
                            <span>{state.day}</span>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="p-0 m-0" />
        </>
    )
}

export default NotificationCard
