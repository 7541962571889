import { Whisper } from 'rsuite'
import { WhisperMenu } from '../../components/WhisperMenu'
import { _actionMenuSpeaker } from './speaker'
import { _pushToastMessage } from '../../helpers/messages'
import { useState } from 'react'
import UserService from '../../services/users.service'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'

interface IHomeheader {
    handleOpenModal: (modal: string, action: string, data: any) => void
    updateTableData: (data: any) => void
    filter: {
        name: string
        relevant_info: string
        created_at: string
        deleted_at: string
        role: string
    }
}

const HomeHeader = ({ handleOpenModal, updateTableData, filter }: IHomeheader) => {
    const [state, setState] = useState<any>({
        search: '',
    })

    const dispatch = useDispatch()

    const _handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault()
            dispatch(setLoading({ isLoading: true }))
            const response = await new UserService().searchUsers(state.search)
            updateTableData(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (e: any) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Ocurrió un error al buscar los usuarios' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleDownloadCSV = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            await new UserService().downloadUsers(filter)
            dispatch(setLoading({ isLoading: false }))
        } catch (e) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Ocurrió un error al descargar el archivo CSV' })
        }
    }

    const _handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const _handleSelectMenu = (eventKey: any, payload: any) => {
        switch (eventKey.option) {
            case 1:
                handleOpenModal('userFormModal', 'create', {})
                break
            case 2:
                handleOpenModal('massiveLoadForm', 'create', {})
                break
            case 4:
                handleOpenModal('invitationFormModal', 'create', {})
                break
            case 5:
                handleOpenModal('filterUserForm', 'filter', {})
                break
            case 6:
                _handleDownloadCSV()
                break
            default:
                _pushToastMessage({ type: 'warning', header: 'Aviso', text: 'Función disponible pronto' })
        }
    }

    const _speaker: any = _actionMenuSpeaker({}, _handleSelectMenu)

    return (
        <header className="flex justify-end items-center gap-2 mb-10">
            <div className="relative flex items-center w-full">
                <form onSubmit={_handleSubmit} className="w-full" autoComplete="off">
                    <input
                        role="presentation"
                        type="text"
                        className="input-text outline-none w-full"
                        placeholder="Busca por nombre o email"
                        value={state.search}
                        name="search"
                        onChange={_handleOnChange}
                        autoComplete="off"
                    />
                    <button type="submit" className="absolute right-0 text-[30px] text-gray-400 me-2">
                        <i className="zmdi zmdi-search" />
                    </button>
                </form>
            </div>

            <div className='flex'>
                <button className="button me-3" onClick={() => handleOpenModal('filterUserForm', 'filter', {})}>
                    <i className="zmdi zmdi-filter-list"></i>
                </button>
                <Whisper
                    placement="auto"
                    trigger="click"
                    speaker={(whisper_payload: any, ref: any) => WhisperMenu(whisper_payload, ref, _speaker)}
                >
                    <button className="button">
                        <i className="zmdi zmdi-plus-circle-o"></i>
                    </button>
                </Whisper>
            </div>
        </header>
    )
}

export default HomeHeader
