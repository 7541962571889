/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { _pushToastMessage } from '../../helpers/messages'
import CardUser from '../../components/DashboardCards/CardUser'
import DashboardService from '../../services/dashboard.service'
import { isAdmin, isCaregiver, isTutor } from '../../helpers/reduxHelpers'
import MainCards from './MainCards'
import { SelectPicker } from 'rsuite'
import CardUserGrowth from '../../components/DashboardCards/CardUserGrowth'
import { useReactToPrint } from 'react-to-print'
import CardRoutineExecution from '../../components/DashboardCards/CardRoutinesExecution'
import CardRoutineWebVsAlexa from '../../components/DashboardCards/CardRoutineWebVsAlexa'

const Dashboard = () => {
    const contentRef = useRef(null)
    const reactToPrintFn = useReactToPrint({ contentRef, bodyClass: 'p-5' })

    const [activeUsers, setActiveUsers] = useState({
        value: '0',
        isLoading: true,
    })

    const [activeCaregivers, setActiveCaregivers] = useState({
        value: '0',
        isLoading: true,
    })

    const [activeTutors, setActiveTutors] = useState({
        value: '0',
        isLoading: true,
    })

    const [activeRoutines, setActiveRoutines] = useState({
        value: '0',
        isLoading: true,
    })
    const [recentActivity, setRecentActivity] = useState({
        value: '0',
        isLoading: true,
    })
    const [usersInfo, setUsersInfo] = useState<any>({
        users: [],
        isLoading: true,
    })

    const [period, setPeriod] = useState({
        periods: [
            { value: '31', label: 'Un mes' },
            { value: '90', label: '3 meses' },
            { value: '180', label: '6 meses' },
        ],
        selected: '31',
    })

    useEffect(() => {
        _getData()
    }, [period.selected])

    const _getData = async () => {
        if (isAdmin()) {
            getActiveUsers()
            getActiveCaregivers()
            getActiveTutors()
            getActiveRoutines()
            getRecentActivity()
        }

        if (isTutor()) {
            getActiveUsers()
            getActiveCaregivers()
            getActiveRoutines()
            getRecentActivity()
            getPatientsInfo()
        }
        if (isCaregiver()) {
            getActiveUsers()
            getActiveTutors()
            getActiveRoutines()
            getRecentActivity()
            getPatientsInfo()
        }
    }

    const getActiveUsers = async () => {
        try {
            setActiveUsers({ value: '0', isLoading: true })
            const response = await new DashboardService().getActiveUsers({ interval: period.selected })
            setActiveUsers({ value: response.data, isLoading: false })
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Error al obtener los usuarios activos' })
        }
    }

    const getActiveCaregivers = async () => {
        try {
            setActiveCaregivers({ value: '0', isLoading: true })
            const response = await new DashboardService().getActiveCaregivers({ interval: period.selected })
            setActiveCaregivers({ value: response.data, isLoading: false })
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Error al obtener los usuarios activos' })
        }
    }

    const getActiveTutors = async () => {
        try {
            setActiveUsers({ value: '0', isLoading: true })
            const response = await new DashboardService().getActiveTutors({ interval: period.selected })
            setActiveTutors({ value: response.data, isLoading: false })
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Error al obtener los usuarios activos' })
        }
    }

    const getActiveRoutines = async () => {
        try {
            setActiveRoutines({ value: '0', isLoading: true })
            const response = await new DashboardService().getActiveRoutines({ interval: period.selected })
            setActiveRoutines({ value: response.data, isLoading: false })
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Error al obtener las rutinas activas' })
        }
    }

    const getRecentActivity = async () => {
        try {
            setRecentActivity({ value: '0', isLoading: true })
            const response = await new DashboardService().getRecentActivity({ interval: period.selected })
            setRecentActivity({ value: response.data, isLoading: false })
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Error al obtener las actividades reciente' })
        }
    }

    const getPatientsInfo = async () => {
        try {
            setUsersInfo({ users: [], isLoading: true })
            const response = await new DashboardService().getPatientsInfo({ interval: period.selected })
            setUsersInfo({ users: response.data, isLoading: false })
        } catch (error) {
            _pushToastMessage({
                type: 'error',
                header: 'Error',
                text: 'Error al obtener la información de los usuarios',
            })
        }
    }

    return (
        <div className="w-full">
            <header className="p-4 flex justify-end">
                <SelectPicker
                    className="w-full sm:w-1/2"
                    data={period?.periods}
                    placeholder="Selecciona un periodo"
                    value={period.selected}
                    onChange={(value) => setPeriod({ ...period, selected: value || '' })}
                />
                <button className='ms-5' onClick={() => reactToPrintFn()}>
                <i className="zmdi zmdi-print text-2xl"></i>
                </button>
            </header>
            <section ref={contentRef}>
                <div className="mb-5 font-semibold text-2xl">Dashboard</div>
                <MainCards
                    activeUsers={activeUsers}
                    activeCaregivers={activeCaregivers}
                    activeTutors={activeTutors}
                    activeRoutines={activeRoutines}
                    recentActivity={recentActivity}
                />
                {!isAdmin() && (
                    <>
                        <hr />
                        <div className="flex justify-start flex-wrap gap-2 mt-10">
                            {usersInfo?.users?.map((user: any, index: number) => (
                                <CardUser
                                    className="w-full sm:w-[calc(33%-0.5rem)] print:w-[calc(50%-0.5rem)]"
                                    user={user}
                                    routines={user?.patient_routines?.length}
                                    activities={user?.logs?.length}
                                    key={index}
                                />
                            ))}
                        </div>
                    </>
                )}

                {isAdmin() && (
                    <>
                        <hr />
                        <div className="w-full flex gap-3 mt-10 flex-wrap">
                            <CardUserGrowth period={period} className="w-full sm:w-[calc(50%-0.5rem)] p-5 mb-10 print:w-full" />
                            <CardRoutineExecution period={period} className="w-full sm:w-[calc(50%-0.5rem)] p-5 mb-10 print:w-full" />
                            <CardRoutineWebVsAlexa period={period} className="w-full sm:w-[calc(50%-0.5rem)] p-5 mb-10 print:w-full" />
                            {/* <CardUserGrowth period={period} className="w-full p-5 mb-10" /> */}
                        </div>
                    </>
                )}
            </section>
        </div>
    )
}

export default Dashboard
