import { isAdmin, isTutor, loggedUserHasPermitionTo } from "../../helpers/reduxHelpers";

export const _actionMenuSpeaker = (data: any, onSelect: any) => {
  return {
    menu: [
      {
        label: "Crear Usuario",
        Icon: "zmdi zmdi-plus-circle-o me-2",
        show: loggedUserHasPermitionTo(["create"]) && (isAdmin() || isTutor()),
        eventKey: 1,
      },
      {
        label: "Importar Usuarios",
        Icon: "zmdi zmdi-upload me-2",
        // show: loggedUserHasPermitionTo(["create"]) && isAdmin(),
        show: false,
        eventKey: 2,
      },
      {
        label: "Descargar Usuarios",
        Icon: "zmdi zmdi-download me-2",
        // show: loggedUserHasPermitionTo(["create"]) && isAdmin(),
        show: false,
        eventKey: 3,
      },
      {
        label: "Enviar invitación",
        Icon: "zmdi zmdi-email me-2",
        show: loggedUserHasPermitionTo(["create"]) && (isAdmin() || isTutor()),
        eventKey: 4,
      },
      {
        label: "Filtros",
        Icon: "zmdi zmdi-filter-list me-2",
        show: true,
        eventKey: 5,
      },
      {
        label: "Descargar CSV",
        Icon: "zmdi zmdi-filter-list me-2",
        show: true,
        eventKey: 6,
      },
    ],
    _handleSelect: (eventKey: any, payload: any) =>
      onSelect({
        option: eventKey,
        payload: payload,
      }),
  };
};
