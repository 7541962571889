/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import IPaginatedPage from '../../interfaces/IPaginatedPage'
import { setLoading } from '../../redux/slicers/loader'
import { useDispatch } from 'react-redux'
import { _pushToastMessage } from '../../helpers/messages'
import LogService from '../../services/logs.service'
import LogsTable from '../../tables/LogsTable'
import PaginationComponent from '../../components/PaginationComponent'
import { SelectPicker } from 'rsuite'

const LogsByRole = () => {
    const [data, setData] = useState<IPaginatedPage>({
        current_page: 0,
        data: [],
        first_page_url: '',
        from: 0,
        last_page: 0,
        last_page_url: '',
        links: [],
        next_page_url: '',
        path: '',
        per_page: 0,
        prev_page_url: '',
        to: 0,
        total: 0,
        sortColumn: '',
        sortType: '',
    })

    const [actions, setActions] = useState<any[]>([])

    const dispatch = useDispatch()

    useEffect(() => {
        _getData()
    }, [])

    const _getData = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new LogService().getUsersLog({ search: '' })
            setData(response.data)

            const actionsResponse = await new LogService().getAllActions()
            const actions: any[] = []
            actionsResponse.data.forEach((action: any) => {
                actions.push({
                    label: action.action,
                    value: action.action,
                })
            })
            setActions(actions)
            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No se pudo cargar las rutinas' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const downloadLogs = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            await new LogService().downloadRecentActivities()
            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No se pudo descargar los logs' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const handleActionsFilter = async (value: any) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new LogService().getUsersLog({ search: value || '' })
            setData(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No se pudo cargar las rutinas' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    return (
        <div className="w-full">
            <header className="w-full flex justify-end border-b-[1px] pb-4">
                <SelectPicker
                    data={actions}
                    locale={{
                        noResultsText: 'No hay resultados',
                        placeholder: 'Filtrar por acciones',
                    }}
                    className="min-w-[30%] mr-3"
                    onChange={handleActionsFilter}
                    loading={actions.length === 0}
                />
                <button className="border px-4 py-1 rounded flex items-center shadow-sm" onClick={downloadLogs}>
                    <i className="zmdi zmdi-download mr-2"></i>
                    Descargar todos logs
                </button>
            </header>

            <div className="w-full mb-5">
                <LogsTable data={data.data} />
            </div>

            <div className="w-full">
                <PaginationComponent pagination={data} onRequestEnd={setData} />
            </div>
        </div>
    )
}

export default LogsByRole
