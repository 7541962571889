import { Notification } from 'rsuite'

export interface IToastMessage {
    type: 'success' | 'error' | 'info' | 'warning'
    header: string
    text: string
    duration?: number
    closable?: boolean
}

const ToastMessage = ({ type, header, text, closable = true }: IToastMessage) => {
    const message = (
        <Notification type={type} header={header} closable={closable} style={{ color: '#000' }}>
            {text}
        </Notification>
    )

    return message
}

export default ToastMessage
