/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import ModalComponent from '../../components/ModalComponent'
import { commands } from '../../pages/ManualAlexa/commands'
import { useNavigate } from 'react-router-dom'

const InfoModal = () => {
    const navigate = useNavigate()
    const [state, setState] = useState({
        open: false,
        dontShow: false,
        command: {
            title: '',
            description: '',
            commands: [],
            example: '',
        },
    })

    const localStorageTips = localStorage.getItem('tips') || ''

    useEffect(() => {
        const randomCommand: any = commands[Math.floor(Math.random() * commands.length)]
        if (localStorageTips) {
            const tips = JSON.parse(localStorageTips)
            if (tips.hasLoggedNow && !tips.dontShowModal) {
                setState({ ...state, open: true, command: randomCommand })
            }
        } else {
            setState({ ...state, open: false, command: randomCommand })
        }
    }, [localStorageTips])

    const _handleClose = () => {
        if (localStorageTips) {
            localStorage.setItem('tips', JSON.stringify({ dontShowModal: state.dontShow, hasLoggedNow: false }))
            setState({ ...state, open: false })
        }
    }

    const _handleOnChange = (e: any) => {
        setState({
            ...state,
            [e.target.name]: e.target.checked,
        })
    }

    const _handleNavigate = () => {
        localStorage.setItem('tips', JSON.stringify({ dontShowModal: state.dontShow, hasLoggedNow: false }))
        setState({ ...state, open: false })
        navigate('/manuals/alexa')
    }
    return (
        <ModalComponent size="sm" open={state.open} title={`Sugerencias`} handleClose={_handleClose}>
            <div className="w-full flex flex-wrap">
                <div className="w-full text-[14px]">A continuación se muestra una información sobre como puedes <i>{state.command.title} en su alexa.</i></div>
                <hr className="w-full" />
                <div className="w-full text-[12px] flex flex-col mb-4">
                    <span className="font-bold">Titulo</span>
                    <span className="ms-2">{state?.command?.title}</span>
                </div>
                <div className="w-full text-[12px] flex flex-col mb-4">
                    <span className="font-bold">Descripción</span>
                    <span className="ms-2">{state?.command?.description}</span>
                </div>
                <div className="w-full text-[12px] flex flex-col mb-4">
                    <span className="font-bold">Comando</span>
                    <div className="ms-2">
                        <ul>
                            {state.command.commands.map((command, index) => (
                                <li key={index}>{command}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="w-full text-[12px] flex flex-col mb-4">
                    <span className="font-bold">Ejample</span>
                    <span className="ms-2">{state?.command?.example}</span>
                </div>
                <div className="w-full flex items-center mt-5">
                    <input type="checkbox" name="dontShow" checked={state.dontShow} onChange={_handleOnChange} />
                    <span className="ms-3 text-[13px]">No volver a mostrar</span>
                </div>

                <div className="w-full flex justify-end mt-5">
                    <button className='button me-3' onClick={_handleClose}>Cerrar</button>
                    <button className='button' onClick={_handleNavigate}>Ver todos comandos</button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default InfoModal
