import instance from './api'
import store from '../redux/store'

class LogService {
    _setTokens() {
        const { session } = store.getState()
        const { tokens } = session
        instance.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`
    }

    async getLogs() {
        this._setTokens()
        try {
            const response = await instance.get(`/api/logs`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getLogsByUser({ id, search }: { id: string; search?: string }) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/logs/${id}?search=${search}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getUsersLog({ search }: { search?: string }) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/logs/action/recent-activities?search=${search}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async downloadLogsByUser(id: string) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/logs/${id}/download`, { responseType: 'blob' })
            const href = URL.createObjectURL(response.data)

            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', 'logs.csv')

            document.body.appendChild(link)

            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(href)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
    async downloadRecentActivities() {
        this._setTokens()
        try {
            const response = await instance.get(`/api/logs/download/recent-activities`, { responseType: 'blob' })
            const href = URL.createObjectURL(response.data)

            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', 'logs.csv')

            document.body.appendChild(link)

            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(href)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getAllActions() {
        this._setTokens()
        try {
            const response = await instance.get(`/api/logs/actions/list`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default LogService
