import moment from 'moment'
import { useNavigate } from 'react-router-dom'

interface ICardUser {
    className?: string
    routines: string
    activities: string
    user: any
}

const CardUser = ({ className = 'w-full', routines, activities, user }: ICardUser) => {
    const navigate = useNavigate()

    const getInitials = (name: string) => {
        const names = name.split(' ')
        if (names.length === 1) return names[0][0]
        if (names.length === 2) return names.map((n) => n[0]).join('')

        return names[0][0] + names[1][0]
    }
    return (
        <div className={`bg-white rounded-lg shadow-md border ${className}`}>
            <div className="flex justify-start items-center px-4 pt-4 pb-2 text-black">
                <div className="text-[20px] flex items-center justify-center font-[400] rounded-full bg-slate-100 w-[35px] h-[35px]">
                    {getInitials(user.name)}
                </div>
                <div className="text-2xl font-[400] ms-3">{user.name}</div>
            </div>
            <div className="px-4 text-slate-500 text-[15px]">
                <div>
                    Role: <span className="text-black">usuario</span>
                </div>
            </div>
            <div className="flex my-4">
                <div className="px-4 text-slate-500 w-1/2 text-[15px]">
                    <i className="zmdi zmdi-calendar"></i>
                    <span className="ms-2">Rutinas:</span>
                    <span className="ms-1 text-black">{routines}</span>
                </div>
                <div className="px-4 text-slate-500 w-1/2 text-[15px]">
                    <i className="zmdi zmdi-calendar text-[17px]"></i>
                    <span className="ms-2">Actividades:</span>
                    <span className="ms-1 text-black">{activities}</span>
                </div>
            </div>
            <button
                className="px-4 py-2 text-[15px] text-blue-500 hover:underline"
                onClick={() => navigate(`/routines/today/${user.id}`)}
            >
                <i className="zmdi zmdi-calendar me-2"></i>
                Ver las rutinas de hoy
            </button>
            <div className="px-4 pb-2">
                <hr className="p-0 m-0" />
            </div>
            <div className="flex mb-2">
                <div className="px-4 text-slate-500 w-full text-[12px]">
                    <i className="zmdi zmdi-check-all"></i>
                    <span className="ms-2">Último inicio de sesión:</span>
                    <span className="ms-2">
                        {user?.last_login
                            ? moment(user?.last_login?.created_at).format('DD/MM/YYYY hh:mm:ss')
                            : 'No se ha conectado'}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default CardUser
