/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { signin } from '../../redux/slicers/session'
import UserService from '../../services/users.service'
import { _pushToastMessage } from '../../helpers/messages'
import { setLoading } from '../../redux/slicers/loader'
import { validateYupSchema } from '../../helpers/utils'
import { ValidateSignInSchema } from '../../validations/user.validations'
import ErrorValidation from '../../components/ErrorValidation'
const { useNavigate } = require('react-router-dom')

const SignIn = () => {
    const [state, setState] = useState({
        email: '',
        password: '',
    })

    const [validations, setValidations] = useState(false)

    const [fieldType, setFieldType] = useState('password')

    const dispatch = useDispatch()
    const { session, loader } = useSelector((state: any) => state)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setLoading({ isLoading: false }))
        if (session.isLogged) {
            if (session?.profile?.roles[0]?.value === 'paciente') {
                navigate('/routines/list/today')
                return
            }

            navigate('/dashboard')
        }
    }, [session])

    const _toggleFieldType = () => {
        if (fieldType === 'password') {
            setFieldType('text')
        } else {
            setFieldType('password')
        }
    }

    const _handleOnChange = (e: any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }

    const _handleSignIn = async () => {
        try {
            const validations = await validateYupSchema(ValidateSignInSchema, state)
            if (validations) {
                setValidations(validations)
                return
            }

            setValidations(false)
            dispatch(setLoading({ isLoading: true }))
            const User = new UserService()
            const sessionData = await User.signinUser(state)
            const profileData = await User.getProfile({ token: sessionData.data.access_token })
            dispatch(
                signin({
                    isLogged: true,
                    profile: {
                        ...profileData.data.data,
                    },
                    tokens: {
                        accessToken: sessionData.data.access_token,
                    },
                })
            )
            let localSotarageTips: string = localStorage.getItem('tips') || ''

            if (localSotarageTips) {
                let tips: { dontShowModal: boolean; hasLoggedNow: boolean } = JSON.parse(localSotarageTips)
                localStorage.setItem('tips', JSON.stringify({ ...tips, hasLoggedNow: true }))
            } else {
                localStorage.setItem('tips', JSON.stringify({ dontShowModal: false, hasLoggedNow: true }))
            }
            
            dispatch(setLoading({ isLoading: false }))
        } catch (e: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Usuario o contraseña invalidos' })
        }
    }

    return (
        <div className="min-h-[100vh] flex justify-center items-center bg-gray-200 text-gray-700">
            <div className="rounded-lg shadow-md bg-white px-8 pt-10 pb-4 md:min-w-[320px] max-w-[90%]">
                <div className="text-center text-[30px] font-bold">Lectogram</div>

                <div className="text-center font-bold mt-3 text-[24px]">Bienvenido</div>

                <div className="mt-2">
                    <input
                        className="shadow-none outline-none w-full border-b-[1px] my-5 text-[13px]"
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={state.email}
                        onChange={_handleOnChange}
                    />
                    <ErrorValidation
                        validations={validations}
                        name="email"
                        className="text-red-400 text-[10px] text-end"
                    />
                </div>

                <div className="relative mt-2">
                    <button className="p-0 m-0 absolute right-0 bottom-5 text-gray-500" onClick={_toggleFieldType}>
                        {fieldType === 'password' ? (
                            <i className="zmdi zmdi-eye"></i>
                        ) : (
                            <i className="zmdi zmdi-eye-off"></i>
                        )}
                    </button>
                    <input
                        className="shadow-none outline-none w-full border-b-[1px] my-5 text-[13px]"
                        type={fieldType}
                        name="password"
                        placeholder="Contraseña"
                        value={state.password}
                        onChange={_handleOnChange}
                    />
                    <ErrorValidation
                        validations={validations}
                        name="password"
                        className="text-red-400 text-[10px] text-end"
                    />
                </div>

                <div className="flex justify-center mt-5">
                    <button
                        className="border-b-blue-300 border-b-2 px-2 rounded-sm hover:text-blue-300"
                        onClick={_handleSignIn}
                        disabled={loader.isLoading}
                    >
                        {loader.isLoading ? 'Iniciando sesion...' : 'Iniciar sesion'}
                    </button>
                    {loader.isLoading && (
                        <span className="relative flex h-3 w-3">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                        </span>
                    )}
                </div>

                <div className="text-center mt-20 text-[12px] ">
                    Olvidó su contraseña?
                    <a href="/recovery" className="text-blue-300 hover:text-blue-400">
                        {' '}
                        Recupera aqui
                    </a>
                </div>
                <div className="text-center mt-10 text-[12px] ">
                    <a className="text-blue-300 hover:text-blue-400" href="/terms-and-conditions" target="_blank">
                        Términos de uso
                    </a>{' '}
                    |{' '}
                    <a className="text-blue-300 hover:text-blue-400" href="/privacy-policies" target="_blank">
                        Políticas de privacidad
                    </a>
                </div>
            </div>
        </div>
    )
}

export default SignIn
