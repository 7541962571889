import instance from './api'
import store from '../redux/store'

class DashboardService {
    _setTokens() {
        const { session } = store.getState()
        const { tokens } = session
        instance.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`
    }

    async getActiveUsers({ interval }: { interval?: string }) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/dashboard/active-users?interval=${interval}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getActiveCaregivers({ interval }: { interval?: string }) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/dashboard/active-caregivers?interval=${interval}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getActiveTutors({ interval }: { interval?: string }) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/dashboard/active-tutors?interval=${interval}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getActiveRoutines({ interval }: { interval?: string }) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/dashboard/active-routines?interval=${interval}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getRecentActivity({ interval }: { interval?: string }) {
        this._setTokens()
        try {
            const response = instance.get(`/api/dashboard/recent-activity?interval=${interval}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getPatientsInfo({ interval }: { interval?: string }) {
        this._setTokens()
        try {
            const response = instance.get(`/api/dashboard/patient-info?interval=${interval}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getUsersGrowth({ interval }: { interval?: string }) {
        this._setTokens()
        try {
            const response = instance.get(`/api/dashboard/users-growth?interval=${interval}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getRoutinesActivities({ interval }: { interval?: string }) {
        this._setTokens()
        try {
            const response = instance.get(`/api/dashboard/routine-activities?interval=${interval}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getRoutinesWebAlexaActivities({ interval }: { interval?: string }) {
        this._setTokens()
        try {
            const response = instance.get(`/api/dashboard/routine-alexa-web-activities?interval=${interval}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default DashboardService
