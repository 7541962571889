/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import CardHeader from '../../components/DashboardCards/CardHeader'
import { isAdmin, isCaregiver, isTutor } from '../../helpers/reduxHelpers'

interface IMainCards {
    activeUsers: {
        value: string
        isLoading: boolean
    }
    activeCaregivers: {
        value: string
        isLoading: boolean
    }
    activeTutors: {
        value: string
        isLoading: boolean
    }
    activeRoutines: {
        value: string
        isLoading: boolean
    }
    recentActivity: {
        value: string
        isLoading: boolean
    }
}
const MainCards = ({ activeUsers, activeCaregivers, activeRoutines, activeTutors, recentActivity }: IMainCards) => {
    const [state, setState] = useState({
        total_users: 0,
        total_caregivers: 0,
        total_tutors: 0,
        total_routines: 0,
        total_activities: 0,
        total_patients: 0,
    })

    useEffect(() => {
        const total_users = sumUsers()
        setState({
            total_users,
            total_patients: activeUsers.value ? parseInt(activeUsers.value) : 0,
            total_caregivers: activeCaregivers.value ? parseInt(activeCaregivers.value) : 0,
            total_tutors: activeTutors.value ? parseInt(activeTutors.value) : 0,
            total_routines: activeRoutines.value ? parseInt(activeRoutines.value) : 0,
            total_activities: recentActivity.value ? parseInt(recentActivity.value) : 0,
        })
    }, [activeUsers, activeCaregivers, activeTutors, activeRoutines, recentActivity])

    const sumUsers = () => {
        let total = 0
        total += activeUsers.value ? parseInt(activeUsers.value) : 0
        total += activeCaregivers.value ? parseInt(activeCaregivers.value) : 0
        total += activeTutors.value ? parseInt(activeTutors.value) : 0
        return total
    }
    return (
        <div className="flex justify-around flex-wrap gap-2 print:justify-start">
            {/* ADMIN VIEW */}
            <CardHeader
                className={`w-full sm:w-[calc(25%-0.5rem)] print:w-[calc(33%-0.5rem)]`}
                title="Todos usuarios activos"
                value={state.total_users.toString()}
                url="/users"
                icon="zmdi-account"
                isLoading={activeUsers?.isLoading}
                show={isAdmin()}
            />

            {/* TUTOR AND CAREGIVER VIEW */}

            <CardHeader
                className={`w-full sm:w-[calc(25%-0.5rem)] print:w-[calc(33%-0.5rem)]`}
                title="Usuarios activos"
                value={state.total_patients.toString()}
                url="/users?role=usuario"
                icon="zmdi-account"
                isLoading={activeUsers?.isLoading}
                show={isTutor() || isCaregiver()}
            />

            {/* TUTOR  VIEW */}
            
            <CardHeader
                className={`w-full sm:w-[calc(25%-0.5rem)] print:w-[calc(33%-0.5rem)]`}
                title="Cuidadores activos"
                value={state.total_caregivers.toString()}
                url="/users?role=cuidador"
                icon="zmdi-account"
                isLoading={activeCaregivers?.isLoading}
                show={isTutor()}
            />

            {/* ALL VIEW */}

            <CardHeader
                className={`w-full sm:w-[calc(25%-0.5rem)] print:w-[calc(33%-0.5rem)]`}
                title="Rutinas activas"
                value={state.total_routines.toString()}
                url="/routines"
                icon="zmdi-calendar"
                isLoading={activeRoutines?.isLoading}
                show={isAdmin() || isTutor() || isCaregiver()}
            />

            <CardHeader
                className={`w-full sm:w-[calc(25%-0.5rem)] print:w-[calc(33%-0.5rem)]`}
                title="Actividad Reciente"
                value={state.total_activities.toString()}
                url="/logs/recent-activities"
                icon="zmdi-trending-up"
                isLoading={recentActivity?.isLoading}
                show={isAdmin() || isTutor() || isCaregiver()}
            />
        </div>
    )
}

export default MainCards
