import { Link } from 'react-router-dom'
import { ReactComponent as FolderIcon } from '../../assets/images/svg/folder-1485.svg'

const Folder = ({ label, url }: { label: string; url: string }) => {
    return (
        <Link to={url} className="p-4 text-gray-500 hover:bg-gray-100">
            <div className="flex flex-col items-center mx-3">
                <FolderIcon style={{ width: '100px', height: 'auto' }} />
                {label}
            </div>
        </Link>
    )
}

const Manuals = () => {
    return (
        <div className="w-full flex justify-start flex-wrap">
            <Folder label="Comandos Alexa" url="/manuals/alexa" />
        </div>
    )
}

export default Manuals
