/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Modal } from 'rsuite'
import RoutineService from '../../services/routine.service'
import PlaceholderParagraph from 'rsuite/esm/Placeholder/PlaceholderParagraph'

const NotificationModal = ({ notification, onClose, open }: any) => {
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState<any>({
        title: '',
        description: '',
        day: '',
        user: {},
        is_read: true,
        token: '',
    })

    useEffect(() => {
        if (open) {
            _handleNotification()
        }
    }, [open])

    const _handleNotification = async () => {
        setLoading(true)
        let routine
        const notificationDay = new Date(notification?.created_at).toLocaleDateString()
        const [hour, minute] = notification?.notifiable?.start_time.split(':')
        let title
        let description

        switch (notification?.type) {
            case 'routine':
            case 'routine:start':
                routine = await new RoutineService().getRoutine(notification?.notifiable?.id)
                title = () => {
                    return (
                        <div className="flex items-center">
                            <div className='w-[76%] truncate'>
                                <i className="zmdi zmdi-notifications-active me-4 !text-[22px] text-blue-500"></i>
                                {notification?.notifiable?.name}
                            </div>
                            <div>
                                <i className="zmdi zmdi-alarm mx-4 !text-[22px] text-blue-600"></i>
                                {`${hour}:${minute}`}
                            </div>
                        </div>
                    )
                }

                description = () => {
                    return (
                        <div className="relative">
                            <div className="text-[14px] text-gray-800 mb-1">{notification?.notifiable?.name}</div>
                            <div className="text-[12px] text-gray-500">{notification?.description}</div>
                        </div>
                    )
                }

                setState({
                    title: title,
                    description: description,
                    user: notification?.user,
                    is_read: notification?.is_read,
                    day: notificationDay,
                    hour: `${hour}:${minute}`,
                    token: routine?.data?.token?.token,
                })
                setLoading(false)
                break

            case 'routine:create':
            case 'routine:update':
            case 'task:create':
            case 'task:update':
                routine = await new RoutineService().getRoutine(notification?.notifiable?.id)
                title = () => {
                    return (
                        <div className="flex items-center">
                            <i className="zmdi zmdi-check-all me-4 !text-[22px] text-blue-500"></i>
                            {notification?.title}
                        </div>
                    )
                }

                description = () => {
                    return (
                        <div className="relative">
                            <div className="text-[12px] text-gray-500">{notification?.description}</div>
                        </div>
                    )
                }

                setState({
                    title: title,
                    description: description,
                    user: notification?.user,
                    is_read: notification?.is_read,
                    day: notificationDay,
                    hour: `${hour}:${minute}`,
                    token: routine?.data?.token?.token,
                })
                setLoading(false)
                break
            default:
                setLoading(false)
                break
        }
    }

    const handleOpenRoutine = () => {
        window.open(`/routines/render/${state.token}`, '_blank')
    }

    return (
        <Modal open={open} onClose={onClose} size={'sm'}>
            <Modal.Header>
                <Modal.Title>
                    {loading && <PlaceholderParagraph rows={1} active />}
                    {!loading && state.title && <state.title />}
                </Modal.Title>
            </Modal.Header>
            <div className="w-full">
                {loading && <PlaceholderParagraph rows={3} active />}
                {!loading && (
                    <div className="flex items-center mt-6 mb-10">{state.description && <state.description />}</div>
                )}
                <div className="flex justify-between items-center mt-4">
                    {loading && <PlaceholderParagraph rows={1} active />}
                    {!loading && (
                        <div className="text-[11px] text-gray-500 flex justify-between mt-2">
                            <span className="me-2">{state.user?.name}</span>
                            <span>{`${state.day} - ${state.hour}`}</span>
                        </div>
                    )}
                    <div>
                        <button className="button me-3" onClick={onClose} disabled={loading}>
                            Cerrar
                        </button>
                        {!loading && state.token && (
                            <button className="button" onClick={handleOpenRoutine} disabled={loading}>
                                Ver Rutina
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default NotificationModal
