/* eslint-disable no-useless-escape */
import * as yup from 'yup'

export const RoutineMediaSchema = yup.object().shape({
    type: yup.string().required('Este campo es requerido'),
    image: yup.string().when('type', {
        is: '1',
        then: (schema) => schema.required('Ingresa una imagen antes de continuar'),
    }),
    url: yup.string().when('type', {
      is: '2',
      then: (schema) => schema.required('Este campo es requerido'),
  }),
})

export type RoutineMediaType = yup.InferType<typeof RoutineMediaSchema>
