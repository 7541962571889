export const commands = [
    {
        title: 'Abrir skill',
        description: 'Siempre que quieras utilizar la skill, debes pedirle a Alexa que la inicie.',
        commands: ['abre asistente personal', 'abrir asistente personal'],
        example: 'Alexa, abrir asistente personal',
    },
    {
        title: 'Informar código de usuario',
        description:
            'Este comando es dado cuando la skill pregunta cuál es el código del usuario. La respuesta debe ser con uno de los siguientes comandos, cambiando la palabra identificador por el id del usuario.',
        commands: ['el código del usuario es {identificador}', 'el código es {identificador}'],
        example: 'el código del usuario es 4',
    },
    {
        title: 'Informar nombre de usuario',
        description:
            'Este comando es dado cuando la skill pregunta cuál es el nombre del usuario. La respuesta debe ser con uno de los siguientes comandos, cambiando la palabra nombre por el primer nombre del usuario.',
        commands: ['el nombre del usuario es {nombre}', 'el nombre es {nombre}'],
        example: 'el nombre del usuario es Matias',
    },
    {
        title: 'Cambiar usuario',
        description: 'Este comando es dado cuando se quiere cambiar el usuario registrado en la skill.',
        commands: ['cambiar usuario'],
        example: 'alexa, cambiar usuario',
    },
    {
        title: 'Listar Rutinas',
        description: 'Luego de informar un id y un nombre de usuario válidos, la skill te preguntará qué quieres hacer. Para que la skill informe cuales rutinas están asociadas a este usuario, se debe usar unos de los siguientes comandos.',
        commands: ['cargar rutinas', 'cargar las rutinas', 'listar rutinas', 'listar las rutinas del usuario'],
        example: 'cargar rutinas',
    },
    {
        title: 'Listar Rutinas de una ubicación',
        description: 'Luego de informar un id y un nombre de usuario válidos, la skill te preguntará qué quieres hacer. Para que la skill informe cuales rutinas están asociadas a una ubicación, se debe usar unos de los siguientes comandos cambiando la palabra ubicacion por la ubicacion deseada.',
        commands: ['cargar rutinas en la {ubicacion}', 'cargar rutinas de la {ubicacion}', 'cargar rutinas en el {ubicacion}', 'cargar las rutinas del {ubicacion}'],
        example: 'cargar rutinas de la cocina',
    },
    {
        title: 'Empezar una rutina',
        description: 'Luego de informar un id y un nombre del usuario válidos, la skill te preguntará qué quieres hacer. Para empezar una rutina, se debe usar uno de los siguientes comandos, cambiando la palabra nombre_rutina por el nombre de la rutina.',
        commands: ['empezar la rutina {nombre_rutina}', 'comenzar la rutina {nombre_rutina}', 'empezar {nombre_rutina}'],
        example: 'empezar cepillarse los dientes',
    },
    {
        title: 'Crear alarmas',
        description: 'Luego de informar un id y un nombre del usuario válidos, la skill te preguntará qué quieres hacer. Para crear las alarmas de las rutinas se debe usar uno de los siguientes comandos.',
        commands: ['crear alarmas', 'crear alarmas de las rutinas'],
        example: 'alexa, crear alarmas',
    },
    {
        title: 'Eliminar alarmas',
        description: 'Luego de informar un id y un nombre del usuario válidos, la skill te preguntará qué quieres hacer. Para eliminar las alarmas de las rutinas se debe usar uno de los siguientes comandos.',
        commands: ['eliminar alarmas', 'eliminar alarmas de las rutinas'],
        example: 'alexa, eliminar alarmas',
    },
]
