import { useEffect, useState } from 'react'
import CardManualAlexa from './CardManualAlexa'
import { commands } from './commands'
import { Link } from 'react-router-dom'

interface ICommands {
    title: string
    description: string
    example: string
    commands: string[]
}

const ManualAlexa = () => {
    const [state, setState] = useState<ICommands[]>([])
    const [search, setSearch] = useState('')
    useEffect(() => {
        setState([...commands])
    }, [])

    const _handleOnSearch = (search: string) => {
        const filteredCommands = commands.filter((command) => {
            return (
                command.title.toLowerCase().includes(search.toLowerCase()) ||
                command.description.toLowerCase().includes(search.toLowerCase())
            )
        })
        setSearch(search)
        setState([...filteredCommands])
    }

    return (
        <div className="w-full">
            <div className="w-full flex items-center mb-5">
                <div className="w-1/4 text-blue-500">
                    <Link to="/manuals" className="text-blue-500 flex items-center">
                        <i className="zmdi zmdi-chevron-left text-[16px] me-2" />
                        volver
                    </Link>
                </div>
                <div className="relative flex items-center w-3/4">
                    <input
                        role="presentation"
                        type="text"
                        className="input-text outline-none w-full"
                        placeholder="Buscar comando"
                        name="search"
                        value={search}
                        onChange={(event) => _handleOnSearch(event.target.value)}
                        autoComplete="off"
                    />
                    <span className="absolute right-0 text-[30px] text-gray-400 me-2" style={{ top: '-2px' }}>
                        <i className="zmdi zmdi-search" />
                    </span>
                </div>
            </div>
            <div className="w-full flex flex-wrap justify-between">
                {state.map((command, index) => (
                    <CardManualAlexa key={index} {...command} search={search} />
                ))}
            </div>
        </div>
    )
}

export default ManualAlexa
