/* eslint-disable no-useless-escape */
import * as yup from 'yup'

export const MedicalHistorySchema = yup.object().shape({
    title: yup
        .string()
        .max(255, 'Este campo acepta un máximo de 255 caracteres')
        .required('Este campo es requerido'),
    description: yup.string().required('Este campo es requerido'),
})

export type MedicalHistoryType = yup.InferType<typeof MedicalHistorySchema>
