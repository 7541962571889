import { useNavigate } from 'react-router-dom'
import PlaceholderParagraph from 'rsuite/esm/Placeholder/PlaceholderParagraph'

interface ICardHeader {
    className?: string
    title: string
    value: string
    url: string
    icon?: string
    isLoading: boolean
    show?: boolean
}

const CardHeader = ({
    className = 'w-full',
    title,
    value,
    url,
    icon = 'zmdi-view-dashboard',
    isLoading,
    show = false,
}: ICardHeader) => {
    const navigate = useNavigate()

    if(!show) return null

    if (isLoading) {
        return (
            <div className={`bg-white rounded-lg shadow-md border ${className}`}>
                <div className="p-4">
                    <PlaceholderParagraph rows={3} active />
                </div>
            </div>
        )
    }

    return (
        <div className={`bg-white rounded-lg shadow-md border ${className}`}>
            <div className="flex justify-between items-center px-4 pt-4 pb-2 text-black">
                <div className="text-[14px] font-[400]">{title}</div>
                <i className={`zmdi ${icon} text-gray-400 text-[20px]`}></i>
            </div>
            <div className="text-[24px] font-bold px-4">{value}</div>
            <button className="text-[12px] px-4 pb-4 pt-2 flex items-end hover:underline" onClick={() => navigate(url)}>
                <span>
                    Ver todos <i className="ms-1 zmdi zmdi-long-arrow-right"></i>
                </span>
            </button>
        </div>
    )
}

export default CardHeader
