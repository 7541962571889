import instance from './api'
import store from '../redux/store'

class NotificationsService {
    _setTokens() {
        const { session } = store.getState()
        const { tokens } = session
        instance.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`
    }

    async getNotifications() {
        this._setTokens()
        try {
            const response = await instance.get('/api/notifications')
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async markAsRead(id: number) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/notifications/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async markAllAsRead() {
        this._setTokens()
        try {
            const response = await instance.put('/api/notifications')
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default NotificationsService
