/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import IPaginatedPage from '../../interfaces/IPaginatedPage'
import RoutineService from '../../services/routine.service'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { _pushToastMessage } from '../../helpers/messages'
import PaginationComponent from '../../components/PaginationComponent'
import { useParams } from 'react-router-dom'
import RoutineCard from '../../components/DashboardCards/RoutineCard'
import ModalComponent from '../../components/ModalComponent'
import QrCodeModal from '../../modals/QrCodeModal'
import { isPatient } from '../../helpers/reduxHelpers'

const DailyRoutines = () => {
    const [data, setData] = useState<IPaginatedPage>({
        current_page: 0,
        data: [],
        first_page_url: '',
        from: 0,
        last_page: 0,
        last_page_url: '',
        links: [],
        next_page_url: '',
        path: '',
        per_page: 0,
        prev_page_url: '',
        to: 0,
        total: 0,
        sortColumn: '',
        sortType: '',
    })

    const [modals, setModals] = useState<any>({
        qrcodeModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
    })

    const params = useParams() as { id: string }
    const dispatch = useDispatch()

    useEffect(() => {
        _getData()
    }, [])

    const _getData = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))

            if (isPatient()) {
                const response = await new RoutineService().getMyDailyRoutines()
                setData(response.data)
            }

            if (!isPatient()) {
                const response = await new RoutineService().getDailyRoutines(params.id)
                setData(response.data)
            }

            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No se pudo cargar las rutinas' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleToggleModal = (modal: string, action: string = 'create', data: any = null) => {
        setModals({
            ...modals,
            [modal]: {
                ...modals[modal],
                data: data ?? modals[modal].data,
                action: action,
                open: !modals[modal].open,
            },
        })
    }

    return (
        <div className="w-full">
            <ModalComponent
                open={modals.qrcodeModal?.open}
                handleClose={() => _handleToggleModal('qrcodeModal')}
                title="Qrcode"
            >
                <QrCodeModal
                    image={modals?.qrcodeModal?.data?.qr_code}
                    link={`/routine/${modals?.qrcodeModal?.data?.id}`}
                    onClose={() => _handleToggleModal('qrcodeModal')}
                    {...modals.qrcodeModal?.data}
                    token={modals.qrcodeModal?.data?.token}
                    url_token="/routines/render/"
                    label_token="Ver tareas"
                />
            </ModalComponent>

            <div className="text-2xl mb-5 font-semibold">Rutinas de hoy</div>

            <div className="w-full flex flex-wrap gap-2">
                {data.data?.length === 0 && (
                    <div className="w-full text-center text-[15px] text-slate-500">No hay rutinas para mostrar</div>
                )}
                {data.data.map((routine: any, index: number) => (
                    <RoutineCard
                        {...routine}
                        routine={routine}
                        key={index}
                        className="w-full sm:w-[calc(33%-0.5rem)]"
                        _handleToggleModal={_handleToggleModal}
                    />
                ))}
            </div>

            <div className="w-full my-5">
                <PaginationComponent pagination={data} onRequestEnd={setData} />
            </div>
        </div>
    )
}

export default DailyRoutines
