import instance from './api'
import store from '../redux/store'

class MedicalHistory {
    _setTokens() {
        const { session } = store.getState()
        const { tokens } = session
        instance.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`
    }

    async getHistories({ user_id }: { user_id: number }) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/medical-histories/${user_id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getMedicalHistory({ id }: { id: string | number }) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/medical-histories/history/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async createHistory(history: any) {
        this._setTokens()
        try {
            const response = await instance.post('/api/medical-histories', history)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updateHistory(history: any) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/medical-histories/${history.id}`, history)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async deleteHistory({ id }: { id: string | number }) {
        this._setTokens()
        try {
            const response = await instance.delete(`/api/medical-histories/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default MedicalHistory
