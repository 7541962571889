import moment from 'moment'
interface IRoutineCard {
    className?: string
    name: string
    created_at: string
    description: string
    qr_code: string
    tasks: any
    token: any
    user: any
    routine: any
    _handleToggleModal: (nameModal: string, action: string, routine: any) => void
}

const RoutineCard = ({
    className = 'w-full',
    qr_code,
    name,
    description,
    created_at,
    tasks,
    token,
    routine,
    _handleToggleModal,
}: IRoutineCard) => {
    const createurlImage = (img: string) => {
        if (img?.includes('http')) {
            return img
        } else {
            return `${process.env.REACT_APP_IMAGE_API}/${img}`
        }
    }
    return (
        <div className={`bg-white rounded-lg shadow-md border flex flex-col justify-between ${className}`}>
            <div className="flex flex-wrap">
                <div className="w-2/3">
                    <div className="flex justify-start items-center px-4 pt-4 pb-2 text-black">
                        <div className="text-2xl font-[400] w-full truncate">{name}</div>
                    </div>
                    <div className="px-4 text-slate-500 text-[15px]">
                        <div>
                            <span className="">{description}</span>
                        </div>
                    </div>

                    <div className="flex my-2 px-4 items-center text-slate-500 text-[15px]">
                        <i className="zmdi zmdi-alarm"></i>
                        <span className="mx-2">Inicio:</span>
                        {`${routine?.start_time?.split(':')[0]}:${routine?.start_time?.split(':')[1]}`}
                    </div>
                    <div className="flex">
                        <div className="px-4 text-slate-500 text-[15px]">
                            <button
                                className=" text-[15px] text-blue-500 hover:underline"
                                onClick={() => window.open(`/routines/render/${token.token}`, '_blank')}
                            >
                                <i className="zmdi zmdi-calendar"></i>
                                <span className="mx-2">Tareas:</span>
                                {tasks?.length}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="w-1/3 flex items-center justify-center">
                    <img
                        onClick={() => _handleToggleModal('qrcodeModal', 'create', routine)}
                        src={createurlImage(qr_code)}
                        alt="qrcode"
                        className="cursor-pointer w-[90px]"
                    />
                </div>
            </div>

            <div className="w-full mt-2">
                <div className="px-4 pb-2">
                    <hr className="p-0 m-0" />
                </div>
                <div className="flex mb-2 py-1">
                    <div className="px-4 text-slate-500 w-full text-[12px]">
                        <i className="zmdi zmdi-check-all"></i>
                        <span className="ms-2">Creado al dia:</span>
                        <span className="ms-2">{moment(created_at).format('DD/MM/YYYY hh:mm:ss')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoutineCard
