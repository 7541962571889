/* eslint-disable react-hooks/exhaustive-deps */
import { SelectPicker, TagPicker } from 'rsuite'
import ModalComponent from '../../components/ModalComponent'
import { useEffect, useState } from 'react'
import { validateYupSchema } from '../../helpers/utils'
import ErrorValidation from '../../components/ErrorValidation'
import { _pushToastMessage } from '../../helpers/messages'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import moment from 'moment'
import { CreatePreLoadedRoutineFormSchema } from '../../validations/routine.validations'
import IUser from '../../interfaces/IUser'
import UserService from '../../services/users.service'
import RoutineService from '../../services/routine.service'
import PreLoadedRoutineService from '../../services/pre_loaded_routine.service'
import { isCaregiver, isTutor } from '../../helpers/reduxHelpers'

interface IRoutineForm {
    open: boolean
    action: string
    data?: any
    handleClose: () => void
    getData: () => void
}
const CreatePreLoadedRoutineForm = ({ handleClose, action, open, data, getData }: IRoutineForm) => {
    const dispatch = useDispatch()
    const { loader } = useSelector((state: any) => state)
    const [state, setState] = useState({
        data: {
            routines: [],
            patient_id: '',
            command_id: '',
            start_time: moment().hour(0).minute(0).second(0).toDate(),
            days_of_week: [],
            description: '',
        },
        validations: {},
    })

    const [patients, setPatients] = useState<IUser[]>([])
    const [routines, setRoutines] = useState<any[]>([])

    useEffect(() => {
        if (!open) return
        setState({
            ...state,
            validations: {},
            data: {
                routines: [],
                patient_id: '',
                command_id: '',
                start_time: moment().hour(0).minute(0).second(0).toDate(),
                days_of_week: [],
                description: '',
            },
        })
    }, [open])

    useEffect(() => {
        if (!open) return
        if (patients.length === 0) _getFormData()
    }, [open])

    const _getFormData = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))

            let patients = null
            if (isTutor()) {
                patients = await new UserService().getTutorPatients()
                setPatients(
                    patients.data?.list_of_patients_to_tutor?.map((patient: IUser) => {
                        return {
                            label: `${patient.name}`,
                            value: patient.id,
                        }
                    })
                )
            }

            if (isCaregiver()) {
                patients = await new UserService().getCaregiverPatients()
                setPatients(
                    patients.data?.list_of_patients_to_caregiver?.map((patient: IUser) => {
                        return {
                            label: `${patient.name}`,
                            value: patient.id,
                        }
                    })
                )
            }
            const preLoadedRoutines = await new PreLoadedRoutineService().getSelectFieldRoutines()
            setRoutines(preLoadedRoutines.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (err: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No fue posible cargar las roles' })
        }
    }

    const _handleOnChangeRsuite = ({ name, value }: { name: string; value: string }) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        })
    }

    const _handleSubmit = async () => {
        try {
            const schema = CreatePreLoadedRoutineFormSchema
            const validations = await validateYupSchema(schema, state.data)
            setState({
                ...state,
                validations,
            })

            if (validations) return

            dispatch(setLoading({ isLoading: true }))

            const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;


            await new RoutineService().createPreLoadedRoutine({...state.data, timezone: timezoneName})

            dispatch(setLoading({ isLoading: false }))

            handleClose()

            getData()

            const message = action === 'create' ? 'Rutina creada con exito' : 'Rutina actualizada con exito'
            _pushToastMessage({ type: 'success', header: 'Exito', text: message })
        } catch (err: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: err.message })
        }
    }

    return (
        <ModalComponent
            open={open}
            title={`${action === 'create' ? 'Crear rutina' : 'Actualizar rutina'}`}
            handleClose={handleClose}
        >
            <div className="w-full flex flex-wrap justify-between">
                <div className="mb-2 w-full">
                    <span className={`text-[12px] `}>Rutina</span>
                    <TagPicker
                        className="w-full "
                        value={state.data?.routines}
                        data={routines}
                        onChange={(value: any) =>
                            _handleOnChangeRsuite({
                                value,
                                name: 'routines',
                            })
                        }
                        placeholder="Selecciona un modelo de rutina"
                        loading={loader.isLoading}
                        locale={{
                            emptyMessage: 'No hay resultados',
                            searchPlaceholder: 'Buscar',
                            noResultsText: 'No hay resultados',
                        }}
                        valueKey="id"
                        labelKey="name"
                    />
                    <ErrorValidation
                        validations={state.validations}
                        name="routine_id"
                        className="text-red-400 text-[11px] text-end"
                    />
                </div>

                <div className="mb-2 w-full">
                    <span className={`text-[12px] `}>Usuario</span>
                    <SelectPicker
                        className="w-full "
                        value={state.data?.patient_id}
                        data={patients}
                        onChange={(value: any) =>
                            _handleOnChangeRsuite({
                                value,
                                name: 'patient_id',
                            })
                        }
                        placeholder="Seleccione uno usuario"
                        loading={loader.isLoading}
                        locale={{
                            emptyMessage: 'No hay resultados',
                            searchPlaceholder: 'Buscar',
                            noResultsText: 'No hay resultados',
                        }}
                    />
                    <ErrorValidation
                        validations={state.validations}
                        name="patient_id"
                        className="text-red-400 text-[11px] text-end"
                    />
                </div>

                {/* <div className="mb-2 w-full">
                    <span className={`text-[12px] `}>Comando</span>
                    <SelectPicker
                        className="w-full "
                        value={state.data?.command_id}
                        data={[]}
                        onChange={(value: any) =>
                            _handleOnChangeRsuite({
                                value,
                                name: 'command_id',
                            })
                        }
                        placeholder="Seleccione un comando"
                        loading={loader.isLoading}
                        locale={{
                            emptyMessage: 'No hay resultados',
                            searchPlaceholder: 'Buscar',
                            noResultsText: 'No hay resultados',
                        }}
                    />
                    <ErrorValidation
                        validations={state.validations}
                        name="command_id"
                        className="text-red-400 text-[11px] text-end"
                    />
                </div> */}

                <div className="flex justify-end w-full mt-10">
                    <button className=" button me-3 border-red-400" onClick={handleClose} disabled={loader.isLoading}>
                        Cerrar
                    </button>
                    <button className="button" onClick={_handleSubmit} disabled={loader.isLoading}>
                        Confirmar
                    </button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default CreatePreLoadedRoutineForm
