import { Table } from 'rsuite'
import WhisperComponent from '../components/WhisperComponent'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import MedicalHistory from '../services/medical_histories.service'
import { _pushToastMessage } from '../helpers/messages'
import { setLoading } from '../redux/slicers/loader'

interface IMedicalHistoryTable {
    data: any
    getHistories: () => void
}
const MedicalHistoryTable = ({ data, getHistories }: IMedicalHistoryTable) => {
    const { loader } = useSelector((state: any) => state)
    const dispatch = useDispatch()

    const _handleViewHistory = ({ id, isediting = 0 }: { id: string | number; isediting?: string | number }) => {
        window.open(`/medical-history/${id}?isediting=${isediting}`, '_blank')
    }

    const _deleteMedicalHistory = async ({ id }: { id: string | number }) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            await new MedicalHistory().deleteHistory({ id })
            _pushToastMessage({ header: 'Éxito', text: 'Información relevante eliminada', type: 'success' })
            getHistories()
        } catch (e) {
            _pushToastMessage({ header: 'Error', text: 'No se pudo eliminar la información relevante', type: 'error' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    return (
        <div className="w-full">
            <Table
                autoHeight
                rowHeight={60}
                className="w-[100%]"
                data={data}
                locale={{ emptyMessage: 'No hay ninguna información relevante' }}
                loading={loader.isLoading}
            >
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">ID</Table.HeaderCell>
                    <Table.Cell>
                        {(item) => {
                            return <WhisperComponent text={`${item.id}`}>{item.id}</WhisperComponent>
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">Titulo</Table.HeaderCell>
                    <Table.Cell>
                        {(item) => {
                            return <WhisperComponent text={`${item.title}`}>{item.title}</WhisperComponent>
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">Fecha creación</Table.HeaderCell>
                    <Table.Cell>
                        {(item) => {
                            return (
                                <WhisperComponent text={`${moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}`}>
                                    <span className="size-08">{`${moment(item.created_at).format(
                                        'DD/MM/YYYY HH:mm:ss'
                                    )}`}</span>
                                </WhisperComponent>
                            )
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">{''}</Table.HeaderCell>
                    <Table.Cell>
                        {(item) => {
                            return (
                                <div className="flex justify-end">
                                    <div className="mr-4">
                                        <WhisperComponent text={`Ver`} capitalizeOff>
                                            <button onClick={() => _handleViewHistory({ id: item.id })}>
                                                <i className="zmdi zmdi-eye"></i>
                                            </button>
                                        </WhisperComponent>
                                    </div>
                                    <div className="mr-4">
                                        <WhisperComponent text={`Editar`} capitalizeOff>
                                            <button onClick={() => _handleViewHistory({ id: item.id, isediting: 1 })}>
                                                <i className="zmdi zmdi-edit"></i>
                                            </button>
                                        </WhisperComponent>
                                    </div>
                                    <div className="mr-4">
                                        <WhisperComponent text={`Eliminar`} capitalizeOff>
                                            <button onClick={() => _deleteMedicalHistory({ id: item.id })}>
                                                <i className="zmdi zmdi-delete"></i>
                                            </button>
                                        </WhisperComponent>
                                    </div>
                                </div>
                            )
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </div>
    )
}

export default MedicalHistoryTable
