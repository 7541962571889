/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { _pushToastMessage } from '../../helpers/messages'
import TaskService from '../../services/task.service'
import { useNavigate, useParams } from 'react-router-dom'
import HeaderTokenPage from '../../components/HeaderTokenPage'
import { sleep } from '../../helpers/utils'
import SoundControl from '../../components/SoundControls'
import ModalComponent from '../../components/ModalComponent'
import MediaControl from './MediaControl'
import RoutineService from '../../services/routine.service'

const TasksToken = () => {
    const [data, setData] = useState<any>({
        days_of_week: '',
        description: '',
        name: '',
        order: 0,
        patient_id: 0,
        qr_code: '',
        start_time: '',
        tasks: [],
        user_id: 0,
    })

    const [taskReaded, setTaskReaded] = useState<any>([])

    const [mediaModal, setMediaModal] = useState<any>({
        data: [],
        open: false,
    })

    const taskRef = useRef<any>(null)
    const [readingAll, setReadingAll] = useState(false)

    const dispatch = useDispatch()

    const params = useParams()

    const navigate = useNavigate()

    useEffect(() => {
        _getData()
        const W = window as any
        const speechSynthesis =
            W.speechSynthesis || W.webkitSpeechSynthesis || W.mozSpeechSynthesis || W.msSpeechSynthesis
        speechSynthesis.cancel()
        return () => {
            speechSynthesis.cancel()
        }
    }, [])

    useEffect(() => {
        if (data?.tasks?.length > 0) {
            if (taskReaded.length === data.tasks?.length) {
                new RoutineService().completeTask({ routine_id: data.id })
            }

            if (taskReaded.length === 1) {
                new RoutineService().startTask({ routine_id: data.id })
            }
        }
    }, [taskReaded])

    const _getData = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new TaskService().getPrivateTasks({ token: params.id || '' })
            response.data.tasks = response.data.tasks.map((item: any) => {
                item.voice = {
                    text: item.name,
                    lang: 'es',
                    isPlaying: false,
                }
                return item
            })
            setData(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({
                type: 'error',
                header: 'Error',
                text: 'No se pudo cargar las tareas',
            })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleNavigate = (event: any) => {
        event.preventDefault()
        navigate(-1)
    }

    const _readDescription = (task: any) => {
        try {
            if (taskRef?.current?.speech?.speaking) {
                if (taskRef.current.task.id === task.id) {
                    const targetTask: any = data?.tasks?.find((item: any) => item.id === task.id)
                    if (targetTask) {
                        targetTask.voice.isPlaying = true
                        setData({ ...data })
                        taskRef.current.speech.resume()
                    }
                    return
                } else {
                    const taskTarget: any = data.tasks?.find((item: any) => item.id === taskRef?.current?.task?.id)
                    if (taskTarget) {
                        taskTarget.voice.isPlaying = false
                        setData({ ...data })
                        window.speechSynthesis.cancel()
                    }
                }
            }

            const speech = new SpeechSynthesisUtterance()

            speech.text = task?.description || ''
            speech.lang = task?.taskconfig?.lang || 'ES-es'
            speech.volume = task?.taskconfig?.volume || 1
            speech.pitch = task?.taskconfig?.pitch || 1
            speech.rate = task?.taskconfig?.rate || 1

            speech.onend = () => {
                const taskTarget: any = data.tasks?.find((item: any) => item.id === task.id)
                if (taskTarget) taskTarget.voice.isPlaying = false
                if (!taskReaded.find((id: any) => id === task.id))
                    setTaskReaded((prevState: any) => [...prevState, task.id])
                setData({ ...data })
            }

            speech.onstart = () => {
                const taskTarget: any = data.tasks?.find((item: any) => item.id === task.id)
                if (taskTarget) taskTarget.voice.isPlaying = true
                setData({ ...data })
            }

            speech.onerror = (error) => {
                console.log(error)
            }

            const W = window as any
            const speechSynthesis =
                W.speechSynthesis || W.webkitSpeechSynthesis || W.mozSpeechSynthesis || W.msSpeechSynthesis
            speechSynthesis.cancel()
            speechSynthesis.speak(speech)

            taskRef.current = {
                task: task,
                speech: speechSynthesis,
            }
        } catch (e) {
            console.log('SPEECH ERROR', e)
        }
    }

    const _readDescriptionPromisse = (task: any) => {
        return new Promise((resolve, reject) => {
            try {
                const speech = new SpeechSynthesisUtterance()
                speech.onend = () => {
                    const taskTarget: any = data.tasks?.find((item: any) => item.id === task.id)
                    if (taskTarget) taskTarget.voice.isPlaying = false
                    if (!taskReaded.find((id: any) => id === task.id))
                        setTaskReaded((prevState: any) => [...prevState, task.id])
                    setData({ ...data })
                    resolve('ended')
                }

                speech.onstart = () => {
                    const taskTarget: any = data.tasks?.find((item: any) => item.id === task.id)
                    if (taskTarget) taskTarget.voice.isPlaying = true
                    setData({ ...data })
                }

                speech.onerror = (error) => {
                    console.log('STOP ERROR')
                    reject(error)
                }

                speech.text = task?.description || ''
                speech.lang = task?.taskconfig?.lang || 'ES-es'
                speech.volume = task?.taskconfig?.volume || 1
                speech.pitch = task?.taskconfig?.pitch || 1
                speech.rate = task?.taskconfig?.rate || 1

                taskRef.current = task

                window.speechSynthesis.speak(speech)
            } catch (e) {
                reject(e)
            }
        })
    }

    const _readAllTasks = async () => {
        try {
            const W = window as any
            const speechSynthesis =
                W.speechSynthesis || W.webkitSpeechSynthesis || W.mozSpeechSynthesis || W.msSpeechSynthesis
            speechSynthesis.cancel()

            data.tasks?.forEach((task: any) => {
                task.voice.isPlaying = false
            })

            setData({ ...data })

            let firstItem: any = null
            for (const task of data?.tasks) {
                setReadingAll(true)

                if (task.deleted_at === null) {
                    if (firstItem === null) {
                        firstItem = document.getElementById(`item-${task.id}`)
                    }
                    const el: any = document.getElementById(`item-${task.id}`)
                    el.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    try {
                        await _readDescriptionPromisse(task)
                    } catch (e) {
                        break
                    }
                    await sleep(task.taskconfig?.delay ? task.taskconfig?.delay * 1000 : 500)
                }
            }
            firstItem?.scrollIntoView({ behavior: 'smooth', block: 'center' })
            setReadingAll(false)
        } catch (e) {
            setReadingAll(false)
        }
    }

    const pauseSpeech = (task: any) => {
        const taskTarget: any = data?.tasks?.find((item: any) => item.id === task.id)
        if (taskTarget) taskTarget.voice.isPlaying = false
        setData({ ...data })
        const W = window as any
        const speechSynthesis =
            W.speechSynthesis || W.webkitSpeechSynthesis || W.mozSpeechSynthesis || W.msSpeechSynthesis
        speechSynthesis.pause()
        taskRef.current = {
            task: task,
            speech: speechSynthesis,
        }
    }

    const stopSpeech = () => {
        const W = window as any
        const speechSynthesis =
            W.speechSynthesis || W.webkitSpeechSynthesis || W.mozSpeechSynthesis || W.msSpeechSynthesis
        speechSynthesis.cancel()

        setReadingAll(false)
        data.tasks?.forEach((task: any) => {
            task.voice.isPlaying = false
        })
        setData({ ...data })
    }

    const _toggleMediaModal = (medias: any[] = []) => {
        setMediaModal({
            data: medias,
            open: !mediaModal.open,
        })
    }

    return (
        <div className="w-full">
            <HeaderTokenPage />
            <ModalComponent open={mediaModal.open} title="Media" handleClose={() => _toggleMediaModal([])} size="full">
                <MediaControl medias={mediaModal.data} open={mediaModal.open} />
            </ModalComponent>
            <div className="h-[90vh] overflow-y-auto w-full pt-20 pb-5">
                {window?.history?.length > 1 && (
                    <a onClick={_handleNavigate} href="/" className="text-blue-500 text-[14px] px-3">
                        <i className="zmdi zmdi-chevron-left me-2 mb-3"></i>
                        Volver
                    </a>
                )}
                <div className="flex flex-wrap gap-4 w-full sm:justify-center justify-between px-5">
                    {data?.tasks?.map((item: any, index: number) => (
                        <div className="w-full flex justify-center" key={index} id={`item-${item.id}`}>
                            <div className="flex max-w-[400px] w-full border rounded">
                                <div className="w-[25%] flex justify-center items-center">
                                    {/* STOP SPEECH */}
                                    {readingAll && item.voice.isPlaying && (
                                        <button
                                            className="text-blue-400 disabled:text-gray-400"
                                            onClick={() => stopSpeech()}
                                        >
                                            <i className="zmdi zmdi-stop ms-2 text-[40px] "></i>
                                        </button>
                                    )}

                                    {/* PAUSE SPEECH */}
                                    {!readingAll && item.voice.isPlaying && (
                                        <button
                                            className="text-blue-400 disabled:text-gray-400"
                                            onClick={
                                                item?.voice?.isPlaying
                                                    ? () => pauseSpeech(item)
                                                    : () => _readDescription(item)
                                            }
                                        >
                                            <i className="zmdi zmdi-pause-circle ms-2 text-[40px] "></i>
                                        </button>
                                    )}

                                    {!readingAll && !item.voice.isPlaying && (
                                        <button
                                            disabled={readingAll && !item.voice.isPlaying}
                                            className="text-blue-400 disabled:text-gray-400"
                                            onClick={() => _readDescription(item)}
                                        >
                                            {item?.voice?.isPlaying ? (
                                                <i className="zmdi zmdi-pause-circle ms-2 text-[40px] "></i>
                                            ) : (
                                                <i className="zmdi zmdi-play-circle ms-2 text-[40px] "></i>
                                            )}
                                        </button>
                                    )}

                                    {readingAll && !item.voice.isPlaying && (
                                        <button
                                            disabled={readingAll && !item.voice.isPlaying}
                                            className="text-blue-400 disabled:text-gray-400"
                                        >
                                            <i className="zmdi zmdi-play-circle ms-2 text-[40px] "></i>
                                        </button>
                                    )}
                                </div>
                                <div className="w-[75%] p-3 flex flex-col">
                                    <div className="flex justify-between mb-3">
                                        <span className="text-[12px] font-bold text-gray-400 text-end w-full">
                                            {data?.name}
                                        </span>
                                    </div>
                                    <h3 className="text-sm font-bold">{item?.name}</h3>
                                    <p className="my-2 text-[12px] ps-1">{item?.description}</p>
                                    {item?.medias?.length > 0 && (
                                        <button
                                            className="w-full text-end text-[10px] text-blue-500 font-semibold py-2"
                                            onClick={() => _toggleMediaModal(item.medias)}
                                        >
                                            ver más
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <SoundControl handleStart={_readAllTasks} handleStop={stopSpeech} reading={readingAll} />
        </div>
    )
}

export default TasksToken
