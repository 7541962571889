/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

const MediaControl = ({ medias, open }: { medias: any[]; open: boolean }) => {
    const [media, setMedia] = useState({
        type: '',
        path: '',
        isYoutube: false,
    })

    useEffect(() => {
        if (open) {
            if (medias.length > 0) {
                const media = medias[0]
                const hasYoutube = media?.path?.includes('youtube')

                if (hasYoutube) {
                    const youtubeId = media?.path?.split('v=')[1]
                    const path = `https://www.youtube.com/embed/${youtubeId}?showinfo=0&autoplay=1&loop=1&mute=1&rel=0&playlist=${youtubeId}`
                    setMedia({
                        ...media,
                        path: path,
                        isYoutube: true,
                    })
                } else {
                    setMedia({ ...media })
                }
            }
        } else {
            setMedia({
                type: '',
                path: '',
                isYoutube: false,
            })
        }
    }, [open])
    return (
        <div className="w-full h-full flex justify-center items-center">
            {media.type === 'video' || media.type === '2' ? (
                <>
                    {media.isYoutube ? (
                        <iframe
                            width="100%"
                            src={media.path}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
                            allowFullScreen
                            style={{ zIndex: 9990 }}
                            className='h-full'
                        ></iframe>
                    ) : (
                        <video className="w-full h-full" controls>
                            <source src={media.path} type="video/mp4" />
                        </video>
                    )}
                </>
            ) : media.type === 'image' || media.type === '1' ? (
                <img className="max-w-full" src={media.path} alt="" />
            ) : (
                <div className="w-full h-full bg-gray-200"></div>
            )}
        </div>
    )
}

export default MediaControl
