/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import InputText from '../../components/InputText'
import ModalComponent from '../../components/ModalComponent'
import { validateYupSchema } from '../../helpers/utils'
import { PasswordUserSchema } from '../../validations/user.validations'
import { _pushToastMessage } from '../../helpers/messages'
import UserService from '../../services/users.service'

const ChangePassword = ({ handleClose, open, data, getUsers }: any) => {
    const [state, setState] = useState({
        currentData: {},
        data: {
            password: '',
            confirm_password: '',
        },
        validations: {},
    })

    useEffect(() => {
        if (!open) return

        setState({
            ...state,
            currentData: data,
            validations: {},
            data: {
                password: '',
                confirm_password: '',
            },
        })
    }, [open])

    const _handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        })
    }

    const _handleSubmit = async () => {
        try {
            const validations = await validateYupSchema(PasswordUserSchema, state.data)
            setState({
                ...state,
                validations,
            })
            if (validations) return

            await new UserService().updatePassword({ id: data.id, password: state.data.password })
            handleClose()
            getUsers()
            _pushToastMessage({ type: 'success', header: 'Éxito', text: 'Contraseña cambiada correctamente' })
        } catch (err: any) {
            _pushToastMessage({
                type: 'error',
                header: 'Error',
                text: err?.response?.data?.message || 'Ocurrió un error al cambiar la contraseña',
            })
        }
    }

    return (
        <ModalComponent open={open} title="Cambiar contraseña" handleClose={handleClose}>
            <div className="w-full flex flex-wrap justify-between">
                <div className="mb-2 w-full sm:w-[48%]">
                    <InputText
                        name="password"
                        type="password"
                        label="Contraseña"
                        validations={state.validations}
                        onChange={_handleOnChange}
                        value={state.data?.password}
                    />
                </div>
                <div className="mb-2 w-full sm:w-[48%]">
                    <InputText
                        name="confirm_password"
                        type="password"
                        label="Confirmar contraseña"
                        validations={state.validations}
                        onChange={_handleOnChange}
                        value={state.data?.confirm_password}
                    />
                </div>
                <div className="flex justify-end mt-10 w-full">
                    <button className="button me-3" onClick={handleClose}>
                        Cerrar
                    </button>
                    <button className="button" onClick={_handleSubmit}>
                        Confirmar
                    </button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default ChangePassword
