/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import IPaginatedPage from '../../interfaces/IPaginatedPage'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { _pushToastMessage } from '../../helpers/messages'
import PaginationComponent from '../../components/PaginationComponent'
import UploadFile from '../../components/UploadFiles'
import ErrorValidation from '../../components/ErrorValidation'
import MedicalHistory from '../../services/medical_histories.service'
import InputText from '../../components/InputText'
import MedicalHistoryTable from '../../tables/MedicalHistoryTable'
import { validateYupSchema } from '../../helpers/utils'
import { MedicalHistorySchema } from '../../validations/medical_history.validations'

interface IMedicalHistories {
    user: { id: number }
    open: boolean
    handleClose: () => void
}

const MedicalHistories = ({ user, open }: IMedicalHistories) => {
    const [state, setState] = useState<any>({
        search: '',
        title: '',
        url_file: '',
        extension: '',
        validations: false,
        clearFileList: 0,
    })

    const [medicalHistories, setMedicalHistories] = useState<IPaginatedPage>({
        current_page: 0,
        data: [],
        first_page_url: '',
        from: 0,
        last_page: 0,
        last_page_url: '',
        links: [],
        next_page_url: '',
        path: '',
        per_page: 0,
        prev_page_url: '',
        to: 0,
        total: 0,
        sortColumn: '',
        sortType: '',
    })

    const dispatch = useDispatch()
    const { loader } = useSelector((state: any) => state)

    useEffect(() => {
        if (open) _getHistory()
    }, [open])

    const _getHistory = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new MedicalHistory().getHistories({ user_id: user.id })
            setMedicalHistories(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Ocurrió un error al buscar los usuarios' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleSubmit = async (e: any) => {
        e.preventDefault()
        const validations = await validateYupSchema(MedicalHistorySchema, state)

        if (validations) {
            setState({ ...state, validations })
            return
        }

        try {
            dispatch(setLoading({ isLoading: true }))
            await new MedicalHistory().createHistory({
                url_file: state.url_file,
                extension: state.extension,
                user_id: user.id,
                title: state.title,
                description: state.description,
            })
            setState({
                ...state,
                title: '',
                description: '',
                url_file: '',
                extension: '',
                validations: false,
                clearFileList: state.clearFileList + 1,
            })

            _getHistory()
            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Ocurrió un error al crear' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleOnRequestEnd = (data: any) => {
        setMedicalHistories(data)
    }

    const handleOnChangeFile = (data: any) => {
        setState({ ...state, [data.name]: data.value.base64, extension: data.value.extension })
    }

    const _handleOnChange = (e: any) => {
        if (state.validations[e.target.name]) {
            const validations = { ...state.validations }
            delete validations[e.target.name]
            setState({ ...state, [e.target.name]: e.target.value, validations })
            return
        }

        setState({ ...state, [e.target.name]: e.target.value })
    }

    return (
        <div className="w-full">
            <form className="w-full" onSubmit={_handleSubmit} autoComplete="off">
                <div className="mb-2 w-full">
                    <InputText
                        name="title"
                        label="Titulo"
                        validations={state.validations}
                        onChange={_handleOnChange}
                        value={state.title}
                    />
                </div>
                <div className="mb-2 w-full">
                    <label className={`${state.validations.description ? 'text-red-400' : ''} text-[12px]`}>
                        Descripcion
                    </label>
                    <textarea
                        className={`w-full outline-none ${
                            state.validations.description ? 'input-text-red' : 'input-text'
                        }`}
                        rows={5}
                        name="description"
                        onChange={_handleOnChange}
                        value={state.description}
                    ></textarea>
                    <ErrorValidation
                        validations={state.validations}
                        name="description"
                        className="text-red-400 text-[11px] text-end"
                    />
                </div>
                {!loader.isLoading && (
                    <div className="mb-2 w-full">
                        <label className="text-[12px]">Ingresar archivo</label>
                        <UploadFile
                            text="Agregar archivo"
                            onChange={(data: any) => handleOnChangeFile({ name: 'url_file', value: data })}
                            accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                        />
                        <ErrorValidation
                            validations={state.validations}
                            name="url_file"
                            className="text-red-400 text-[11px] text-start"
                        />
                    </div>
                )}
                <div className="w-full flex justify-end">
                    <button type="submit" className="border border-blue-400 text-blue-400 px-4 py-1 rounded">
                        Agregar
                    </button>
                </div>
            </form>

            <hr className='w-full' />

            <div className="w-full">
                <MedicalHistoryTable data={medicalHistories.data} getHistories={_getHistory} />
            </div>
            <div className="w-full mt-3">
                <PaginationComponent pagination={medicalHistories} onRequestEnd={_handleOnRequestEnd} />
            </div>
        </div>
    )
}

export default MedicalHistories
