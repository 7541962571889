/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { useEffect, useState } from 'react'
import PlaceholderParagraph from 'rsuite/esm/Placeholder/PlaceholderParagraph'
import DashboardService from '../../services/dashboard.service'
import { _pushToastMessage } from '../../helpers/messages'
import { Tooltip, Whisper } from 'rsuite'

interface ICardRoutineWebVsAlexa {
    className?: string
    period?: any
}

const CardRoutineWebVsAlexa = ({ className = 'w-full', period }: ICardRoutineWebVsAlexa) => {
    const [routineActivitiesData, setRoutineActivitiesData] = useState({
        chart: {
            web: 0,
            alexa: 0,
            total: 0,
            bars: [],
        },
        dates: {
            start: moment().format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
        },
        is_loading: true,
    })

    useEffect(() => {
        getUsersGrowth()
    }, [period])

    const getUsersGrowth = async () => {
        try {
            setRoutineActivitiesData({ ...routineActivitiesData, is_loading: true })
            const response = await new DashboardService().getRoutinesWebAlexaActivities({ interval: period.selected })
            setRoutineActivitiesData({
                ...routineActivitiesData,
                ...response.data,
                is_loading: false,
            })
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Error al obtener las actividades reciente' })
        }
    }

    const Circle = ({ color, width = 'w-5', height = 'h-5' }: { color: string; width?: string; height?: string }) => {
        return <div className={`rounded-full ${width} ${height} ${color}`}></div>
    }
    if (routineActivitiesData.is_loading) {
        return (
            <section className={`bg-white rounded-lg shadow-md border text-slate-400 text-sm  ${className}`}>
                <PlaceholderParagraph rows={4} active />
            </section>
        )
    }

    return (
        <section className={`bg-white rounded-lg shadow-md border text-slate-400 text-sm  ${className}`}>
            <div className="w-full font-semibold mb-5 flex justify-between items-center">
                <span className="flex items-center">
                    <i className={`zmdi zmdi-calendar text-gray-400 text-[18px] me-3`} />
                    Rutinas web vs alexa
                </span>
                <span className="flex items-center">
                    <button className="m-0 p-0">
                        <i className={`zmdi zmdi-file-text text-blue-400 text-[18px]`} />
                    </button>
                </span>
            </div>
            <div className="w-full flex">
                <div className="w-1/2 flex items-center text-[11px] sm:text-[13px]">
                    <div className="w-1/3">
                        <div className="text-black">Alexa</div>
                        <div className="flex items-center">
                            <Circle color="bg-slate-600" width="w-4" height="h-4" />
                            <span className="ms-2">{routineActivitiesData.chart?.alexa}</span>
                        </div>
                    </div>
                    <div className="w-1/3">
                        <div className="text-black">Web</div>
                        <div className="flex items-center">
                            <Circle color="bg-slate-400" width="w-4" height="h-4" />
                            <span className="ms-2">{routineActivitiesData.chart?.web}</span>
                        </div>
                    </div>
                    <div className="w-1/3">
                        <div className="text-black">Total</div>
                        <div className="flex items-center">
                            <Circle color="bg-slate-400" width="w-4" height="h-4" />
                            <span className="ms-2">{routineActivitiesData.chart?.total}</span>
                        </div>
                    </div>
                </div>
                <div className="w-1/2">
                    <div className="w-full h-[100px] flex overflow-hidden items-end gap-2">
                        {routineActivitiesData.chart?.bars?.map((bar: any, index: number) => (
                            <Whisper
                                key={index}
                                trigger="hover"
                                placement={'autoVerticalEnd'}
                                controlId={`control-id-auto`}
                                speaker={<Tooltip>{`${bar.label} - ${parseInt(bar.percentage)}%`}</Tooltip>}
                            >
                                <div
                                    className={`w-[49%] ${bar.color}  rounded z-10`}
                                    style={{
                                        height: `${
                                            bar.percentage === 0 ? 5 : bar.percentage > 100 ? 100 : bar.percentage
                                        }%`,
                                    }}
                                />
                            </Whisper>
                        ))}
                    </div>
                </div>
            </div>
            <div className="w-full flex text-[11px] justify-between mt-5">
                <div className="sm:w-1/2">
                    <span className="font-semibold me-2 ">Fecha inicio consulta:</span>{' '}
                    {moment(routineActivitiesData.dates.start).format('DD/MM/yyyy')}
                </div>
                <div className="border-l-2 ps-3 sm:w-1/2 sm:text-end">
                    <span className="font-semibold me-2">Fecha termino consulta:</span>{' '}
                    {moment(routineActivitiesData.dates.end).format('DD/MM/yyyy')}
                </div>
            </div>
        </section>
    )
}

export default CardRoutineWebVsAlexa
